import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import ReCaptcha from '@matt-block/react-recaptcha-v2'
import axios from 'axios'
import AWN from 'awesome-notifications'
import Spinner from '../../components/Spinner'

const ContactForm = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [disable, setDisable] = useState(true);
    const [gatCaptcha, setCaptcha] = useState();

    const [Loginshow, setLoginShow] = useState(false);
    const handleLoginClose = () => setLoginShow(false);
    const handleLoginShow = () => setLoginShow(true);

    const onSubmit = (data, e) => {
        handleLoginShow();
        const payload = { name: data.name, email: data.email, subject: data.subject, message: data.message, captcha: gatCaptcha };
        axios.post(
            `https://api.pentotest.com/contact`, payload)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                    handleLoginClose();
                } else if (res.data.status === 3) {
                    new AWN().alert(`${res.data.message}`);
                    handleLoginClose();
                    window.location.reload();
                } else {
                    new AWN().success(`${res.data.message}`);
                    handleLoginClose();
                    e.target.reset();
                }
            }).catch(error => {
                console.log(error);
            });

    }

    function onChange(value) {
        if (value !== null) {
            setCaptcha(value);
            setDisable(false);
        } else {
            setDisable(true);
        }
    }

    return (
        <Fragment>
            <form className="widget-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row gx-4 gx-xxl-5 px-0">
                    <div className="col-md-6 mb-10">
                        <div className="form-input-box">
                            <label className="fs-16 text-white">Name: </label>
                            <input type="text" placeholder="Full Name*" {...register('name', { required: true, maxLength: 20 })}
                            />
                            {errors.name && (
                                <div className="text-danger">Please enter full name.</div>)}
                            {errors.name &&
                                errors.name.type === "maxLength" &&
                                <div className="text-danger">Please enter valid full name.</div>}
                        </div>
                    </div>
                    <div className="col-md-6 mb-10">
                        <div className="form-input-box">
                            <label className="fs-16 text-white">Email Id: </label>
                            <input type="email" placeholder="Email Address*" {...register('email', { required: true, maxLength: 30, pattern: /^\S+@\S+$/i })}
                            />
                            {errors.email &&
                                errors.email.type === "required" &&
                                <div className="text-danger">Please enter email address.</div>}
                            {errors.email &&
                                errors.email.type === "maxLength" &&
                                <div className="text-danger">Please enter valid email address.</div>}
                            {errors.email &&
                                errors.email.type === "pattern" &&
                                <div className="text-danger">Please enter valid email address.</div>}
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-10">
                    <div className="form-input-box">
                        <label className="fs-16 text-white">Subject: </label>
                        <input type="text" placeholder="Subject*" {...register('subject', { required: true, maxLength: 150 })}
                        />
                        {errors.subject &&
                            errors.subject.type === "required" &&
                            <div className="text-danger">Please enter subject.</div>}
                        {errors.subject &&
                            errors.subject.type === "maxLength" &&
                            <div className="text-danger">Please enter valid subject.</div>}
                    </div>
                </div>
                <div className="col-md-12 mb-20">
                    <div className="form-input-box">
                        <label className="fs-16 text-white">Comments: </label>
                        <textarea placeholder="Write Message*" {...register('message', { required: true, maxLength: 300 })} />
                        {errors.message &&
                            errors.message.type === "required" &&
                            <div className="text-danger">Please enter message.</div>}
                        {errors.message &&
                            errors.message.type === "maxLength" &&
                            <div className="text-danger">Please enter valid message.</div>}
                    </div>
                    <ReCaptcha
                        siteKey="6Lcd74MkAAAAAFzbtAGPRmgoP-T9cH6YaXAsaP_q"
                        theme="light"
                        size="normal"
                        onSuccess={(captcha) => onChange(captcha)}
                        onExpire={() => onChange(null)}
                        onError={() => onChange(null)}
                    />
                </div>
                <div className={Loginshow ? `divOne` : 'divOne d-none'}>
                    <div className="text-center">
                        <Spinner />
                    </div>
                </div>
                <div className="col-12">
                    <button className="theme_btn" disabled={disable}>Send Message</button>
                </div>
            </form>
        </Fragment >
    )
}

export default ContactForm