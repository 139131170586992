import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'



//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import Social from '../../../components/social/Social'
import Skill from '../../../components/skill/Skill'
import Newsletter from '../../../components/call-to-action/Newsletter'
import Footer from '../../../components/footer/Footer'


const TeamDetails = () => {
    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Team-Details | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />

                {/* PageTitle */}
                <PageTitle title="Team Details" />
                {/* PageTitle */}

                {/*team-details-wrapper start*/}
                <section className="team-details-wrapper pt-145 pb-100 pt-lg-60 pb-lg-20">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-lg-12">
                                <div className="team-details-content-top mb-60 d-lg-flex align-items-center justify-content-space-between">
                                    <div className="team-img pe-md-1">
                                        <img src={process.env.PUBLIC_URL + "/assets/img/about/about-me1.png"} alt="img" />
                                    </div>
                                    <div className="team-info ps-md-3 ps-xxl-5 pt-lg-5">
                                        <h3 className="team-title mb-25">Shahrukh Khan</h3>
                                        <p className="fs-20 text-white mb-40">Information Security Researcher | VAPT | Web Developer | Ethical Hacker | Application Security</p>
                                        <div className="d-md-flex justify-content-space-between">
                                            <ul className="list-none pe-5 me-4">
                                                <li>
                                                    <div className="info">
                                                        <div className="icon">
                                                            <i className="bi bi-envelope" />
                                                        </div>
                                                        <div className="info-content">
                                                            <h5>Email</h5>
                                                            <p>support@pentotest.com</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="info">
                                                        <div className="icon">
                                                            <i className="bi bi-telephone" />
                                                        </div>
                                                        <div className="info-content">
                                                            <h5>Phone</h5>
                                                            <p>+91 99909 34025</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="info">
                                                        <div className="icon">
                                                            <i className="bi bi-clock" />
                                                        </div>
                                                        <div className="info-content">
                                                            <h5>Experiences</h5>
                                                            <p>5+ Years</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className="list-none">
                                                <li className="pb-2">
                                                    <div className="info">
                                                        <div className="icon">
                                                            <i className="bi bi-translate" />
                                                        </div>
                                                        <div className="info-content">
                                                            <h5>Language</h5>
                                                            <p>English, Hindi</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <h4 className="social-title mb-20">Follow More:</h4>
                                                    {/* SocialThree start */}
                                                    <Social />
                                                    {/* SocialThree end */}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12">
                                <div className="widget-left-section">
                                    <h3 className="sect-title-two fw-bold mb-20">Biography</h3>
                                    <p className="text-white">In over five years of experience as a website builder and hybrid mobile application developer, I have also provided security for web applications. My expertise lies in creating visually appealing websites and mobile apps that prioritize security, using the latest industry trends and best practices to ensure my clients' digital assets are protected.</p>
                                    {/* SkillTwo start */}
                                    <Skill />
                                    {/* SkillTwo end */}
                                    <h3 className="sect-title-two fw-bold mb-20">Our Skills</h3>
                                    <p className="text-white">I hold several certifications in web development and web design, including HTML/CSS/JavaScript and CEH. These certifications have provided me with the knowledge and skills necessary to create dynamic and visually appealing websites that meet the needs of my clients. By staying up-to-date with the latest industry trends and best practices, I am able to provide my clients with the highest quality services possible.</p>
                                    <div className="tools-list mt-30 mb-40">
                                        <span><img src={process.env.PUBLIC_URL + "/assets/img/icon/html-5.png"} alt="html" /></span>
                                        <span><img src={process.env.PUBLIC_URL + "/assets/img/icon/css-3.png"} alt="css" /></span>
                                        <span><img src={process.env.PUBLIC_URL + "/assets/img/icon/js.png"} alt="js" /></span>
                                        <span><img src={process.env.PUBLIC_URL + "/assets/img/icon/php.png"} alt="php" /></span>
                                        <span><img src={process.env.PUBLIC_URL + "/assets/img/icon/c.png"} alt="c++" /></span>
                                        <span><img src={process.env.PUBLIC_URL + "/assets/img/icon/python.png"} alt="python" /></span>
                                        <span><img src={process.env.PUBLIC_URL + "/assets/img/icon/typescript.png"} alt="typescript" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*team-details-wrapper end*/}



                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}
            </div>

        </Fragment>
    )
}

export default TeamDetails