import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import AWN from "awesome-notifications"
import AvatarEditor from 'react-avatar-editor'

export default function Profile() {

    let editor = "";
    const [img, setImg] = useState();
    const [scale, setState] = useState();

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);


    const [dataf, setUsers] = useState([]);
    const [datafollowers, setFollowers] = useState([]);
    const [datafollowing, setFollowing] = useState([]);

    const { register, handleSubmit, formState: { errors }, } = useForm();
    const onSubmit = dat => {
        const data = { name: dat.name, description: dat.description, user_id: localStorage.getItem("user_id") }
        axios.post(
            `https://api.pentotest.com/update-account`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    new AWN().success(`${res.data.message}`);
                    localStorage.setItem("name", res.data.data[0].name);
                    localStorage.setItem("description", res.data.data[0].description);
                    window.location.href = '/account';
                }
            }).catch(error => {
                console.log(error);
            });
    }


    function profile() {
        const data1 = { user_id: localStorage.getItem("user_id") };
        axios.post(`https://api.pentotest.com/account`, data1)
            .then(res => {
                if (res.data.status === 1) {
                    setUsers(res.data.data);
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });
    }

    function following() {
        const data3 = { followers: localStorage.getItem("user_id") };
        axios.post(`https://api.pentotest.com/fetchfollowing`, data3)
            .then(res => {
                if (res.data.status === 1) {
                    setFollowing(res.data.data);
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });
    }

    function followers() {
        const data2 = { following: localStorage.getItem("user_id") };
        axios.post(`https://api.pentotest.com/fetchfollowers`, data2)
            .then(res => {
                if (res.data.status === 1) {
                    setFollowers(res.data.data);
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });
    }
    
    function onImageChange(e) {
        const [file] = e.target.files;
        setImg(URL.createObjectURL(file));
    }

    function handleScale(e) {
        setState(e.target.value)
    };

    function setEditorRef(e) {
        editor = e;
    };

    function handleSave(e) {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();
            const data = { user_id: localStorage.getItem("user_id"), user_image: String(croppedImg) };
            axios.post(
                `https://api.pentotest.com/update-profile`, data)
                .then(res => {
                    if (res.data.status === 0) {
                        new AWN().alert(`${res.data.message}`);
                    } else {
                        new AWN().success(`${res.data.message}`);
                        localStorage.setItem("user_image", String(croppedImg));
                        window.location.href = '/account';
                    }
                }).catch(error => {
                    console.log(error);
                });

        }
    };

    useEffect(() => {

        const data1 = { user_id: localStorage.getItem("user_id") };
        axios.post(`https://api.pentotest.com/account`, data1)
            .then(res => {
                if (res.data.status === 1) {
                    setUsers(res.data.data);
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });

        const data2 = { following: localStorage.getItem("user_id") };
        axios.post(`https://api.pentotest.com/fetchfollowers`, data2)
            .then(res => {
                if (res.data.status === 1) {
                    setFollowers(res.data.data);
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });

        const data3 = { followers: localStorage.getItem("user_id") };
        axios.post(`https://api.pentotest.com/fetchfollowing`, data3)
            .then(res => {
                if (res.data.status === 1) {
                    setFollowing(res.data.data);
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });

    }, []);

    const followClick = (follow_id) => {
        const data = { follow_id: follow_id }
        axios.post(`https://api.pentotest.com/unfollow`, data)
            .then(res => {
                if (res.data.status === 1) {
                    new AWN().success(`${res.data.message}`);
                    following();
                    followers();
                    profile();
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });

    }

    const FollowersModel = () => {
        handleShow1()
    }

    const FollowingModal = () => {
        handleShow2()
    }

    const editProfileModal = () => {
        handleShow3()
    }

    const editProfileImageModal = () => {
        handleShow4()
    }

    return (
        <Fragment>
            {/* Followers */}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header>
                    <Modal.Title><b className="text-success">Followers</b></Modal.Title>
                    <Button variant="link" onClick={handleClose1}><i className="fa fa-times text-dark" aria-hidden="true"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    {datafollowers.map(data => (
                        <div key={data.user_id}>
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4">
                                    <img className="dpavatar" src={data.user_image === 'default_image.png' ? process.env.PUBLIC_URL + '/assets/img/default_image.png' : data.user_image} alt={data.name} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-4 col-4">
                                    <h5 className="text-capitalize">{data.name}</h5>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                    <button type="button" className="btn btn-dark rounded-pill" onClick={event => followClick(data.followers_id)}>Remove</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
            {/* Following */}
            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header>
                    <Modal.Title><b className="text-success">Following</b></Modal.Title>
                    <Button variant="link" onClick={handleClose2}><i className="fa fa-times text-dark" aria-hidden="true"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    {datafollowing.map(data => (
                        <div key={data.user_id}>
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-4 col-4">
                                    <img className="dpavatar" src={data.user_image === 'default_image.png' ? process.env.PUBLIC_URL + '/assets/img/default_image.png' : data.user_image} alt={data.name} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-4 col-4">
                                    <h5 className="text-capitalize">{data.name}</h5>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                    <button type="button" className="btn btn-dark rounded-pill" onClick={event => followClick(data.followers_id)}>Remove</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
            {/* EditProfile */}
            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header>
                    <Modal.Title><b className="text-success">Update Profile</b></Modal.Title>
                    <Button variant="link" onClick={handleClose3}><i className="fa fa-times text-dark" aria-hidden="true"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label className="text-dark">Full Name</label>
                                    <input
                                        className="form-control text-capitalize"
                                        type="text"
                                        defaultValue={localStorage.getItem('name')}
                                        placeholder="Full Name*"
                                        {...register('name', { required: true, maxLength: 20 })}
                                    />
                                    {errors.name &&
                                        errors.name.type === "required" &&
                                        <small className="text-danger">Please enter full name.</small>}
                                    {errors.name &&
                                        errors.name.type === "maxLength" &&
                                        <small className="text-danger">Please enter valid full name.</small>}
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="form-group">
                                    <label className="text-dark">Description you profile</label>
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        placeholder="Description"
                                        rows="3"
                                        defaultValue={localStorage.getItem('description')}
                                        {...register('description', { required: true, maxLength: 200 })}
                                    ></ textarea>
                                    {errors.description &&
                                        errors.description.type === "required" &&
                                        <small className="text-danger">Please enter description.</small>}
                                    {errors.description &&
                                        errors.description.type === "maxLength" &&
                                        <small className="text-danger">Please enter description less than 200 words.</small>}
                                </div>
                            </div>
                            <div className="col-lg-12 mt-20 mb-20">
                                <button className="btn btn-outline-success" type="submit">Update</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            {/* EditProfileImage */}
            <Modal show={show4} onHide={handleClose4}>
                <Modal.Header>
                    <Modal.Title><b className="text-success">Update Profile</b></Modal.Title>
                    <Button variant="link" onClick={handleClose4}><i className="fa fa-times text-dark" aria-hidden="true"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12">
                            <input className="form-control" type="file" onChange={onImageChange} accept="image/*" />
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="text-center">
                                <AvatarEditor
                                    ref={setEditorRef}
                                    image={img}
                                    width={200}
                                    height={200}
                                    border={50}
                                    color={[255, 255, 255, 0.6]} // RGBA
                                    scale={scale ? parseFloat(scale) : parseFloat('1.2')}
                                    rotate={0}
                                />
                                <br />
                                <input
                                    className="form-control"
                                    name="scale"
                                    type="range"
                                    onChange={handleScale}
                                    min="1"
                                    max="10"
                                    step="0.01"
                                    defaultValue="1"
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose4}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="blog-single-area pt-50 pb-0 pt-lg-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="recent_post_dtls pl-3 pr-3">
                                <div className="row">
                                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <div className="form-hid">
                                            <label onClick={editProfileImageModal}>
                                                <div className="profile-picture">
                                                    <div id="uploaded_image" className="profile-pic"
                                                        style={{ backgroundImage: localStorage.getItem("user_image") === 'default_image.png' ? 'url(' + process.env.PUBLIC_URL + '/assets/img/default_image.png)' : 'url(' + localStorage.getItem("user_image") + ')' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" className="bi bi-camera"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                            <path
                                                                d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                        </svg>
                                                        <span>Update Profile</span>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <div className="pl-0">
                                            <h5 className="text-capitalize text-white">
                                                {localStorage.getItem('name')}
                                            </h5>
                                            <p className="mt-1 mb-2 text-white">
                                                {localStorage.getItem("description")}
                                            </p>
                                            {dataf.map(data => (
                                                <div key={data.user_id}>
                                                    <Link className="ms-1"><button type="button" className="btn btn-success rounded-pill">Posts {data.posts}</button></Link>
                                                    <Link className="ms-1"><button type="button" className="btn btn-success rounded-pill" onClick={FollowersModel}>Followers {data.followers}</button></Link>
                                                    <Link className="ms-1"><button type="button" className="btn btn-success rounded-pill" onClick={FollowingModal}>Following {data.following}</button></Link>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12 col-12 mt-1">
                                        <input type="button" className="profile-edit-btn" onClick={editProfileModal} value="Edit Profile" />
                                    </div>
                                </div>
                                <div className="pt-3 pb-3">
                                    <div className="hr"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <Link className="ms-1" to="/account"><button type="button" className="btn btn-light">My Article</button></Link>
                                        <Link className="ms-1" to="/account/write"><button type="button" className="btn btn-light">Write A Article</button></Link>
                                        <Link className="ms-1" to="/account/my-products"><button type="button" className="btn btn-light">My Courses</button></Link>
                                        <Link className="ms-1" to="/account/feedback"><button type="button" className="btn btn-light">Feedback</button></Link>
                                        <Link className="ms-1" to="/account/sendmail"><button type="button" className="btn btn-light">Send Mail</button></Link>
                                        <Link className="ms-1" to="/account/account-delete"><button type="button" className="btn btn-danger">Delete Account</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}