import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import AWN from 'awesome-notifications'
import TypeWriterEffect from 'react-typewriter-effect'
import { Button, Modal } from 'react-bootstrap'
import '../../../assets/css/blog-css.css'

//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import CategoryWidgetTwo from '../../../components/widget/CategoryWidgetTwo'
import WidgetPost from '../../../components/widget/WidgetPost'
import BlogAuthor from '../../../components/blog/BlogAuthor'
//import BlogComment from '../../../components/blog/BlogComment'
//import CommentForm from '../../../components/form/CommentForm'
import Newsletter from '../../../components/call-to-action/Newsletter'
import Footer from '../../../components/footer/Footer'
import WidgetFour from '../../../components/widget/WidgetFour'


const BlogDetails = () => {

    const { slug } = useParams();
    const [datas, setUsers] = useState([]);
    const [disable, setDisable] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function reload() {
        const data = { slug: slug }
        axios.post(`https://api.pentotest.com/viewblogs`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setUsers(res.data.data)
                }
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        setTimeout(() => {
            handleShow();
        }, 30000);
        setTimeout(() => {
            handleShow();
        }, 5000);
        const data = { slug: slug }
        axios.post(`https://api.pentotest.com/viewblogs`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setUsers(res.data.data);
                }
            }).catch(error => {
                console.log(error);
            });
    }, [slug]);

    const reactionsClick = (blog_id, reaction) => {
        const data = { blog_id: blog_id, reaction: reaction }
        axios.post(`https://api.pentotest.com/reaction`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    new AWN().success(`${res.data.message}`);
                    setDisable(true);
                    reload();
                }
            }).catch(error => {
                console.log(error);
            });
    }

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title><b className="text-success">Cellular Network Hacking Course</b></Modal.Title>
                    <Button variant="link" onClick={handleClose}><i className="fa fa-times text-dark" aria-hidden="true"></i></Button>
                </Modal.Header>
                <Modal.Body><div className="blog_dtl_thumb"><Link to="/course/cellular-network-hacking-course"><img src="/assets/img/cellular_network_hacking.gif" alt="cellular_network_hacking" /></Link></div></Modal.Body>
                <Modal.Footer>
                    <Link to="/course/cellular-network-hacking-course"><Button variant="primary">Buy</Button></Link>
                </Modal.Footer>
            </Modal>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                {datas.map(data => (
                    <Helmet key={100}>
                        <title>{data.title} | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                        <link rel="canonical" href={`https://www.pentotest.com/blogs/${data.slug}`}></link>
                    </Helmet>
                ))}
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <Header />
                {/* ThemeMenuThree */}

                {/* PageTitle */}
                {datas.map(data => (
                    <PageTitle key={1} title={data.title} />
                ))}
                {/* PageTitle */}

                {/*blog-single-area start*/}
                <section className="blog-single-area pt-150 pb-80 pt-lg-60">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-8 mb-60">
                                <div className="blog-style-five mb-25">
                                    <div className="blog-content">
                                        {datas.map(data => (
                                            <span key={2} className="blog-tag pb-15 text-capitalize">
                                                {data.category}
                                            </span>
                                        ))}
                                        {datas.map(data => (
                                            <h3 key={3}><a className="blog-title" href={`https://www.pentotest.com/blogs/${data.slug}`}>
                                                <TypeWriterEffect
                                                    textStyle={{
                                                        color: '#FFFFFF',
                                                        fontWeight: 500,
                                                        fontSize: '1.0em',
                                                    }}
                                                    startDelay={100}
                                                    cursorColor="black"
                                                    text={data.title}
                                                    typeSpeed={100}
                                                    scrollArea={document}
                                                />
                                            </a>
                                            </h3>
                                        ))}
                                        {datas.map(data => (
                                            <div key={4} className="blog-meta mb-35">
                                                <a href={() => false} className="mr-20"><i className="bi bi-person-plus" /> {data.author}</a>
                                                <a href={() => false} className="mr-20"><i className="bi bi-clock" /> {data.created_at}</a>
                                                <a href={`https://www.facebook.com/sharer/sharer.php?u=https://www.pentotest.com/blogs/${data.slug}`} className="mr-20" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                                                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.pentotest.com/blogs/${data.slug}`} className="mr-20" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                                <a href={`https://twitter.com/intent/tweet?url=https://www.pentotest.com/blogs/${data.slug}&text=`} className="mr-20" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                                                <a href={`https://pinterest.com/pin/create/button/?url=https://www.pentotest.com/blogs/${data.slug}&media=&description=`} className="mr-20" target="_blank" rel="noopener noreferrer"><i className="fab fa-pinterest"></i></a>
                                            </div>
                                        ))}
                                    </div>
                                    {datas.map(data => (
                                        <div key={5} className="blog-thumb mb-40">
                                            <img className="blog-image-size w-100" src={data.file} alt={data.title} />
                                        </div>
                                    ))}
                                </div>
                                {datas.length < 1 ? <h4 className="mx-3 text-info">"No blogs are available," or "This blog may be private..</h4> :
                                    <>
                                        {datas.map(data => (
                                            <div className="view-blog-css" key={6} dangerouslySetInnerHTML={{ __html: data.description }}>
                                            </div>
                                        ))}
                                    </>
                                }

                                {/* BlogAuthorTwo start */}
                                <BlogAuthor />
                                {/* BlogAuthorTwo end */}
                                <div className="row gx-4 gx-xxl-5 text-center align-items-center author-profile mx-md-0 mt-60">
                                    <h3 className="post-title mb-50 text-center">Reactions</h3>
                                    {datas.map(data => (
                                        <div key={5}>
                                            <div className="emoji">
                                                <div className="block">
                                                    <button type="submit" disabled={disable} onClick={event => reactionsClick(data.blog_id, 'like')} className="btn btn-outline-primary"><i
                                                        className="fa fa-thumbs-up image " aria-hidden="true">Like</i></button>
                                                </div>
                                                <p id="likes">{data.like}</p>
                                            </div>
                                            <div className="emoji">
                                                <div className="block">
                                                    <button type="submit" disabled={disable} onClick={event => reactionsClick(data.blog_id, 'dislike')} className="btn btn-dark"><i
                                                        className="fa fa-thumbs-down image"
                                                        aria-hidden="true">Dislike</i></button>
                                                </div>
                                                <p id="dislikes">{data.dislike}</p>
                                            </div>
                                            <div className="emoji">
                                                <div className="block">
                                                    <button type="submit" disabled={disable} onClick={event => reactionsClick(data.blog_id, 'love')} className="btn btn-outline-danger"><i
                                                        className="fa fa-heart image" aria-hidden="true">Love</i></button>
                                                </div>
                                                <p id="loves">{data.love}</p>
                                            </div>
                                            <div className="emoji">
                                                <div className="block">
                                                    <button type="submit" disabled={disable} onClick={event => reactionsClick(data.blog_id, 'happy')} className="btn btn-outline-warning"><i
                                                        className="fa fa-smile-o image" aria-hidden="true">Happy</i></button>
                                                </div>
                                                <p id="happys">{data.happy}</p>
                                            </div>
                                            <div className="emoji">
                                                <div className="block">
                                                    <button type="submit" disabled={disable} onClick={event => reactionsClick(data.blog_id, 'average')} className="btn btn-outline-info"><i
                                                        className="fa fa-meh-o image" aria-hidden="true">Average</i></button>
                                                </div>
                                                <p id="averages">{data.average}</p>
                                            </div>
                                            <div className="emoji">
                                                <div className="block">
                                                    <button type="submit" disabled={disable} onClick={event => reactionsClick(data.blog_id, 'sad')} className="btn btn-dark"><i
                                                        className="fa fa-frown-o image" aria-hidden="true">Sad</i></button>
                                                </div>
                                                <p id="sads">{data.sad}</p>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                {/* <div className="comments-wrapper  mt-55">
                                    <h3 className="post-title mb-50">Comments</h3>
                                    <BlogComment />
                                </div>
                                <div className="comment-form mb-10 mt-65">
                                    <h3 className="post-title mb-35">Leave a comment</h3>
                                    <CommentForm />
                                </div> */}
                            </div>
                            <div className="col-lg-4">
                                <div className="right-widget-section">
                                    <div className="widget widget-categories-list mb-40">
                                        <div className="widget-title-box mb-30">
                                            <h4 className="widget-sub-title2 fs-20">Categories</h4>
                                        </div>
                                        {/* CategoryWidgetTwo start */}
                                        <CategoryWidgetTwo />
                                        {/* CategoryWidgetTwo end */}
                                    </div>
                                    <div className="widget widget-post mb-60">
                                        <div className="widget-title-box mb-30">
                                            <h4 className="widget-sub-title2 fs-20">Recent Post</h4>
                                        </div>
                                        {/* WidgetPost start */}
                                        <WidgetFour />
                                        {/* WidgetPost end */}
                                    </div>
                                    <div className="widget widget-categories-tag mb-60">
                                        <div className="widget-title-box mb-25">
                                            <h4 className="widget-sub-title2 fs-20">Tags</h4>
                                        </div>
                                        <div className="tag-list">
                                            {datas.map(data => (
                                                <div key={8}>
                                                    {data.keywords.map((val, i) => (
                                                        <Link key={i + 20}>{val}</Link>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="widget widget-post mb-60">
                                        <div className="widget-title-box mb-30">
                                            <h4 className="widget-sub-title2 fs-20">Popular Post</h4>
                                        </div>
                                        <ul className="post-list">
                                            <WidgetPost />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*blog-single-area end*/}

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default BlogDetails