import React, { Fragment, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from 'axios'
import AWN from 'awesome-notifications'
import { Button, Modal } from 'react-bootstrap'

//componets
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'
import LoginModal from '../../../components/modal/LoginModal'

const Cart = () => {

    const [disables, setDisables] = useState(false);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const cookies = new Cookies();
    const getCart = cookies.get('Cart');

    const Rmcart = () => {
        cookies.remove('Cart');
        window.location.href = '/cart';
    };

    function PlaceOrder() {
        setDisables(true)
        window.paypal.Buttons({
            style: {
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: 'paypal',

            },

            createOrder: function (data, actions) {
                return actions.order.create({
                    purchase_units: [{ "amount": { "currency_code": "USD", "value": 30.0 } }]
                });
            },

            onApprove: function (data, actions) {
                return actions.order.capture().then(function (orderData) {
                    const payloads = { user_id: localStorage.getItem("user_id"), id: orderData.id, status: orderData.status, paypal_email_address: orderData.purchase_units[0].payee.email_address, merchant_id: orderData.purchase_units[0].payee.merchant_id, create_time: orderData.create_time };
                    axios.post(`https://api.pentotest.com/checkout`, payloads)
                        .then(res => {
                            if (orderData.status === 'COMPLETED') {
                                new AWN().success(`Payment successful.`);
                                window.location.href = '/account/my-products';
                            } else {
                                new AWN().alert(`Payment failed.`);
                            }
                        }).catch(error => {
                            new AWN().alert(`Payment failed.`);
                        });
                });
            },

            onError: function (err) {
                new AWN().alert(`Payment failed.`);
            }
        }).render('#paypal-button-container');
    }

    const loginModel = () => {
        handleShow1()
    }

    useEffect(() => {
        const loadPayPalScript = async () => {
            try {
                await addScriptToHead('https://www.paypal.com/sdk/js?client-id=Ad2j80H8-IVw21G_YmiBWzkVKYIKq-pf5CSHNZvICD6_QbyRRmQyuf9vEItWMIilCxasRpqkJpK0FXRa&currency=USD');
            } catch (error) {
                console.error(error);
            }
        };
        loadPayPalScript();
    }, []);

    return (
        <Fragment>
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header>
                    <Modal.Title><b className="text-success">Welcome Back!</b></Modal.Title>
                    <Button variant="link" onClick={handleClose1}><i className="fa fa-times text-dark" aria-hidden="true"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    <LoginModal />
                </Modal.Body>
            </Modal>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Cart | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <Header />
                {/* ThemeMenuThree */}
                {getCart ?
                    <section className="gradient-bg our-services service-bg-three pt-95 pb-55 pt-lg-55 pb-lg-15">
                        <img
                            className="service-shape-3c"
                            src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-6b.svg"}
                            alt="shape" />
                        <div className="container">
                            <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                                <div className="col-xl-5 col-lg-6 col-md-8">
                                    <div className="section-title text-center mb-55">
                                        <h3 className="sect-title mb-25" data-aos="fade-up">YOUR CART</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                                <div className="row feature-style-five">
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                                        <div className="mb-0">
                                                            <img className="cart-image-size" src="/assets/img/course/imsi-course.gif" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-10 mt-60">
                                                        <h5 className="text-capitalize text-white text-start">Cellular Network Hacking Course</h5>
                                                        <p className="text-white text-start"><del>$200</del><strong className="pl-10">$30</strong></p>
                                                        <p className="text-success text-start">85.0% Off 1 offer applied <i className="fa fa-info-circle" aria-hidden="true"></i></p>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-2 mt-75">
                                                        <Link onClick={event => Rmcart()} className="text-danger"><i className="bi bi-trash" aria-hidden="true"></i></Link>
                                                    </div>
                                                    <p className="mt-15 mb-15"><i className="fa fa-check-circle" aria-hidden="true"></i>  Safe and Secure Payments.Easy returns.100% Authentic products.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-0">
                                                <div className="feature-style-five mb-0">
                                                    <div className="mb-30">
                                                        <h4 className="text-white mb-50">PRICE DETAILS</h4>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div className="row">
                                                                    <div className="col-lg-9 col-md-9 col-sm-9 col-9">
                                                                        <h6 className="text-white mt-15 mb-15">Price (1 item)</h6>
                                                                        <h6 className="text-white mt-15 mb-15">Discount</h6>
                                                                        <h6 className="text-white mt-15 mb-15">Delivery Charges</h6>
                                                                        <div className="mt-15 mb-15"><div className="hr"></div></div>
                                                                        <h5 className="text-white">Total Amount</h5>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                                                        <h6 className="text-white mt-15 mb-15">$200</h6>
                                                                        <h6 className="text-success mt-15 mb-15">$170</h6>
                                                                        <h6 className="text-success mt-15 mb-15">FREE</h6>
                                                                        <div className="mt-15 mb-15"><div className="hr"></div></div>
                                                                        <h5 className="text-white">$30</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-15 mb-15"><div className="hr"></div></div>
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                    <p className="text-success">You will save $180 on this order</p>
                                                                    <br />
                                                                    <div id="smart-button-container">
                                                                        <div style={{ textAlign: "center" }}>
                                                                            <div id="paypal-button-container"></div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="text-end">
                                                                        {localStorage.getItem('user_id') ?
                                                                            <button onClick={event => PlaceOrder()} className="btn btn-warning" disabled={disables}>PLACE ORDER</button>
                                                                            :
                                                                            <button type="button" className="btn btn-primary" onClick={loginModel}>PLACE ORDER</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="blog-classic-area pt-50 pb-50 pt-lg-50 pb-lg-15">
                        <div className="container">
                            <div className="row gx-4 gx-xxl-5">
                                <div className="col-lg-12 col-md-12">
                                    {/* BlogStyleFour start */}
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="text-center">
                                                <img src={process.env.PUBLIC_URL + "/assets/img/product/cart/empcart.png"} className="thumbnail-size" alt="" />
                                                <h5>Your cart is empty!</h5>
                                                <small>Add items to it now.</small>
                                                <br />
                                                <Link to="/courses"><button className="btn btn-primary">SHOP NOW</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* BlogStyleFour end */}
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment >
    )
}

export default Cart

const addScriptToHead = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.head.appendChild(script);
    });
};