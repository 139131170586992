import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import ThemeMainMenu from './ThemeMainMenu'
import SearchModal from '../modal/SearchModal'
import SideBar from '../sidebar/SideBar'
import MobileMenu from './MobileMenu'
import Cookies from 'universal-cookie'

const Header = () => {

    const cookies = new Cookies();
    const [sticky, setSticky] = useState(false);
    const [modalIsopen, setOpen] = useState(false)
    const [showSidebar, setShowSidebar] = useState(false)
    const [menu, setMenu] = useState("")

    const openModal = () => {
        setOpen(true);
    }
    const closeModal = () => {
        setOpen(false);
    }

    const openSidebar = () => {
        setShowSidebar(true)
    }
    const closeSidebar = () => {
        setShowSidebar(false)
    }
    const showMobilemenu = () => {
        setMenu('show')
    }
    const hideMobilemenu = () => {
        setMenu()
    }

    const stickyMenu = () => {
        if (window.scrollY >= 60) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };
    window.addEventListener('scroll', stickyMenu);

    return (
        <Fragment>
            <SearchModal isOpen={modalIsopen} onClick={closeModal} />
            <SideBar onClick={closeSidebar} value={showSidebar} />
            <header className="theme-main-menu py-lg-0">
                <div className={sticky ? "main-header-area sticky-menu" : "main-header-area"}>
                    <div className="container custom-container-nav">
                        <div className="row gx-4 gx-xxl-5 align-items-center">
                            <div className="col-xxl-2 col-lg-2 col-6 text-start">
                                <div className="logo-area">
                                    <Link to="/"><img src={process.env.PUBLIC_URL + "/assets/img/logo/header-logo-1.png"} alt="Header-logo" /></Link>
                                </div>
                            </div>
                            <div className="col-xxl-8 col-lg-8 d-none d-lg-block">
                                <nav className="navbar navbar-expand-lg">
                                    <div className="container-fluid">
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <ThemeMainMenu />
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div className="col-xxl-2 col-lg-2 col-6 text-end">
                                <ul className="right-nav mb-0 d-flex align-items-center justify-content-end">
                                    <li className="search-area">
                                        <a
                                            className="search_input"
                                            href={() => false}
                                            onClick={openModal}
                                        >
                                            <i className="bi bi-search" />
                                        </a>
                                    </li>
                                    <li>
                                        {cookies.get('Cart') ?
                                            <a className="shopping-cart" href="/cart">
                                                <i className="bi bi-bag" />
                                                <span className="badge">1</span>
                                            </a>
                                            :
                                            <a className="shopping-cart" href="/cart">
                                                <i className="bi bi-bag" />
                                                <span className="badge">0</span>
                                            </a>
                                        }
                                    </li>
                                    <li>
                                        <div className="hamburger-menu">
                                            <div className="d-none d-lg-inline-block">
                                                <a className="round-menu" href={() => false} onClick={openSidebar}>
                                                    <i className="bi bi-list" />
                                                </a>
                                            </div>
                                            <a className="round-menu d-lg-none" href={() => false} onClick={showMobilemenu}>
                                                <i className="bi bi-list" />
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.theme-main-menu */}
                <MobileMenu onClick={hideMobilemenu} active={menu} />
            </header>
            {/* header-area end */}

        </Fragment>
    )
}

export default Header