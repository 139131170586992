import React,{Fragment} from 'react'
import { Link } from 'react-router-dom'

const servicesContent = [
    {
        name: 'SiteMap',
        routerPath: '/sitemap',
    },
    {
        name: 'Terms & Conditions',
        routerPath: '/terms-and-conditions',
    },
    {
        name: 'Privacy Policy',
        routerPath: '/privacy-policy',
    },
    {
        name: 'Refunds And Cancellations',
        routerPath: '/refunds-and-cancellations'
    }
]

const CopyRightThree = () => {
  return (
    <Fragment>
        <div className="copyright-three">
            <div className="container">
                <div className="row gx-4 gx-xxl-5 pt-60 align-items-center">
                    <div className="col-xl-6 col-lg-6 pb-0">
                        <ul
                            className="fot-list text-center d-sm-flex align-items-center justify-content-center justify-content-lg-start">
                           {servicesContent.map((val,i)=>(
                             <li key={i}>
                                <Link to={val.routerPath}>{val.name}</Link>
                            </li>
                           ))}
                        </ul>
                    </div>
                    <div className="col-xl-6 col-lg-6  text-center pb-30">
                        <p className="text-white mb-20">
                            <a href="https://www.pentotest.com">www.pentotest.com</a>
                            © {new Date().getFullYear()}, All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default CopyRightThree