import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import AWN from 'awesome-notifications'
import ReCaptcha from '@matt-block/react-recaptcha-v2'
import Spinner from '../../components/Spinner'

const LoginModal = () => {

    const { register, handleSubmit, formState: { errors }, } = useForm()
    const [disable, setDisable] = useState(true);
    const [gatCaptcha, setCaptcha] = useState()

    const [Loginshow, setLoginShow] = useState(false)
    const handleLoginClose = () => setLoginShow(false)
    const handleLoginShow = () => setLoginShow(true)

    const onSubmit = (data, e) => {
        handleLoginShow()
        const payload = { email: data.email, password: data.password, captcha: gatCaptcha }
        axios.post(
            `https://api.pentotest.com/login`, payload)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`)
                    handleLoginClose()
                } else if (res.data.status === 3) {
                    new AWN().alert(`${res.data.message}`)
                    handleLoginClose()
                    window.location.reload()
                } else if (res.data.status === 400) {
                    new AWN().alert(`${res.data.message}`)
                    handleLoginClose()
                } else {
                    handleLoginClose()
                    e.target.reset();
                    localStorage.setItem("user_id", res.data.data[0].user_id);
                    localStorage.setItem("name", res.data.data[0].name);
                    localStorage.setItem("description", res.data.data[0].description);
                    localStorage.setItem("user_image", res.data.data[0].user_image);
                    localStorage.setItem("token", res.data.data[0].token);
                    localStorage.setItem("role", res.data.data[0].role);
                    window.location.reload();
                }
            }).catch(error => {
                console.log(error)
            });
    }

    useEffect(() => {
        localStorage.clear()
    }, []);

    function onChange(value) {
        if (value !== null) {
            setCaptcha(value);
            setDisable(false);
        } else {
            setDisable(true);
        }
    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form_box mb-30">
                            <input
                                className="form-control"
                                type="email"
                                placeholder="Email Address*"
                                {...register('email', { required: true, maxLength: 30, pattern: /^\S+@\S+$/i })}
                            />
                            {errors.email &&
                                errors.email.type === "required" &&
                                <div className="text-danger">Please enter email address.</div>}
                            {errors.email &&
                                errors.email.type === "maxLength" &&
                                <div className="text-danger">Please enter valid email address.</div>}
                            {errors.email &&
                                errors.email.type === "pattern" &&
                                <div className="text-danger">Please enter valid email address.</div>}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form_box mb-30">
                            <input
                                className="form-control"
                                type="password"
                                placeholder="Password*"
                                {...register('password', { required: true, maxLength: 20 })}
                            />
                            {errors.password &&
                                errors.password.type === "required" &&
                                <div className="text-danger">Please enter password.</div>}
                            {errors.password &&
                                errors.password.type === "maxLength" &&
                                <div className="text-danger">Please enter valid password.</div>}

                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input mt-2" />
                            <label className="form-check-label text-dark">Remember Me <Link
                                className="text-primary" to="/forgot-password">Forgot password?</Link></label>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <ReCaptcha
                            siteKey="6Lcd74MkAAAAAFzbtAGPRmgoP-T9cH6YaXAsaP_q"
                            theme="light"
                            size="normal"
                            onSuccess={(captcha) => onChange(captcha)}
                            onExpire={() => onChange(null)}
                            onError={() => onChange(null)}
                        />
                    </div>
                    <div className="col-lg-12 mt-3">
                        <button className="btn btn-outline-primary" type="submit" disabled={disable}>Log
                            In</button>
                        <div className={Loginshow ? `divOne` : 'divOne d-none'}>
                            <div className="text-center">
                                <Spinner animation="border" variant="danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    )
}

export default LoginModal