import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

//componets
import Header from '../../components/header/Header'
import BlogStyle from '../../components/blog/BlogStyle'
import Newsletter from '../../components/call-to-action/Newsletter'
import Footer from '../../components/footer/Footer'
import Profile from './Profile'


const Account = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />
                <Profile />
                {/* PageTitle */}

                {/*blog-grid-area start*/}
                <section className="blog-grid-area pt-150 pb-105 pt-lg-60 pb-lg-15">
                    <div className="container">
                        {/* BlogStyleTwo start */}
                        <BlogStyle />
                        {/* BlogStyleTwo start */}
                        {/* <div className="row">
                            <div
                                className="col-lg-12 d-flex align-items-center justify-content-center mb-45">
                                <Link className="theme_btn" to="/blog-details-v1">Load More</Link>
                            </div>
                        </div> */}
                    </div>
                </section>

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Account