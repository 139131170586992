import React, { Fragment } from 'react'
import PageTitle from '../components/page-title/PageTitle'
import { Helmet } from 'react-helmet'

import Header from '../components/header/Header'
import Newsletter from '../components/call-to-action/Newsletter'
import Footer from '../components/footer/Footer'

const NotFound = () => {
    return (
        <Fragment>
            <div className="main-page-wrapper">

                {/* Helmet start */}
                <Helmet>
                    <title>404 || Techy – IT Solution & Web Security React Template</title>
                </Helmet>
                {/* Helmet end */}
                <Header />

                {/*page-title-area start*/}
                <PageTitle title="Error 404" />
                {/*page-title-area end*/}

                {/*error-page start*/}
                <div className="error-page pt-150 pb-115 pt-lg-60 pb-lg-50">
                    <div className="error-page-bg">
                        <img
                            className="error-shape d-none d-lg-inline-block"
                            src="assets/img/shape/line-round-11f.svg"
                            alt="Shape" />
                        <div className="container">
                            <div className="row gx-4 gx-xxl-5">
                                <div className="col-lg-12">
                                    <div className="error-page-wrapper mb-60">
                                        <div className="section-title text-center mb-60">
                                            <h2 className="sect-title text-white">Oopsss!<br />Page not found</h2>
                                        </div>
                                        <img className="w-100" src={process.env.PUBLIC_URL + "assets/img/error/404.svg"} alt="error-images" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*error-page end*/}

                {/*call-to-action start*/}
                <Newsletter />
                {/*call-to-action end*/}

                <Footer />
            </div>

        </Fragment>
    )
}

export default NotFound