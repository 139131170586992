import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import Footer from '../../../components/footer/Footer'
import ContactForm from '../../../components/form/ContactForm'
import Social from '../../../components/social/Social'

const Contact = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Contact | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />

                {/* PageTitle */}
                <PageTitle title="Contact" />
                {/* PageTitle */}

                {/*contact-section start*/}
                <div className="contact-section pt-145 pb-100 pt-lg-60 pb-lg-15">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-5">
                                <div className="contact-info-wrapper me-xxl-5 pe-xxl-5 mb-45">
                                    <h2 className="sect-title mb-5">Let’s Get Connect With Us</h2>
                                    <div className="single-info-box mb-50">
                                        <div className="icon">
                                            <i className="bi bi-telephone" />
                                        </div>
                                        <span className="mb-10">(+91) 99909-34025</span>
                                    </div>
                                    <div className="single-info-box mb-50">
                                        <div className="icon">
                                            <i className="bi bi-geo-alt" />
                                        </div>
                                        <span className="mb-10">Basara, Basara Barabanki,</span>
                                        <span>India 225305.</span>
                                    </div>
                                    <div className="single-info-box mb-50">
                                        <div className="icon">
                                            <i className="bi bi-envelope" />
                                        </div>
                                        <span className="mb-10">support@pentotest.com</span>
                                        <span>shah96rukh@gmail.com</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="contact-content-wrapper mb-45">
                                    <div className="contact-map pb-40 mb-xxl-4">
                                        <iframe title="Office Location on map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25640.95776161058!2d81.17978733673404!3d27.105000774139548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399942d9184f1109%3A0xea5ca1975058cd30!2sBasara%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1613824542118!5m2!1sen!2sin" />
                                    </div>
                                    <div className="row gx-4 gx-xxl-5 px-0 align-items-center mb-25">
                                        <div className="col-xxl-7 col-md-6">
                                            <div className="text-center text-md-start">
                                                <p className="text-white mb-40">Your email address will not be published. Required fields are marked*.</p>
                                            </div>
                                        </div>
                                        <div className="col-xxl-5 col-md-6">
                                            {/* SocialFive start */}
                                            <Social />
                                            {/* SocialFive end */}
                                        </div>
                                    </div>
                                    <div className="contact-form-two">
                                        <h3 className="post-title mb-50">Send Us Message</h3>
                                        {/* ContactForm start */}
                                        <ContactForm />
                                        {/* ContactForm end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*contact-section end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Contact