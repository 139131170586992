import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const BlockStyleAbout = () => {
    return (
        <Fragment>
            <div className="block-style-one ps-lg-1 mb-45">
                <h2 className="mb-15">OUR EXPERIENCE</h2>
                <p>Hello and welcome to my website. My name is Shahrukh Khan, and I am a highly skilled cybersecurity professional with over five years of experience in the field. I am based in India and have a passion for helping individuals and organizations improve their cybersecurity posture to protect their valuable assets.
                    My expertise lies in network security, data protection, threat analysis, and incident response. In addition to my experience, I hold the Certified Ethical Hacker (CEH) practical certification, which is a testament to my skills and knowledge in the field.
                    Throughout my career, I have demonstrated a commitment to staying up-to-date with the latest cybersecurity trends and technologies, and I pride myself on being a lifelong learner in this ever-evolving field. My goal is to help my clients identify and mitigate cyber threats and improve their overall security posture.
                    Thank you for visiting my website, and please don't hesitate to contact me if you have any questions or if you would like to discuss how I can assist you with your cybersecurity needs.</p>
                <ul className="block-box box-two list-none">
                    <li>
                        <a href="contact.html">
                            <span className="img-bg"><img src={process.env.PUBLIC_URL + "/assets/img/icon/icon-2a.svg"} alt="icon" /></span>
                            <span>24/7 Online Support</span>
                        </a>
                    </li>
                </ul>
                <Link className="theme_btn mt-60" to="/team-details">More Details</Link>
            </div>
        </Fragment>
    )
}

export default BlockStyleAbout