import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import axios from 'axios'
import AWN from 'awesome-notifications'

//componets
import Header from '../../components/header/Header'
import Newsletter from '../../components/call-to-action/Newsletter'
import Footer from '../../components/footer/Footer'
import AdminProfile from './AdminProfile';


const Admin = () => {

    const [datas, setUsers] = useState([]);

    const fetchData = () => {
        const data = { user_id: localStorage.getItem("user_id"), token: localStorage.getItem("token") }
        axios.post(`https://api.pentotest.com/subscriber/651b135223f0a1fc2ce2e6a6`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setUsers(res.data.data)
                }
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchData()
    }, [])

    const rmsubs = (subs_email) => {
        const data = { subs_email: subs_email, user_id: localStorage.getItem("user_id"), token: localStorage.getItem("token") }
        axios.post(`https://api.pentotest.com/subscriber/delete/651b135223f0a1fc2ce2e6a6`, data)
            .then(res => {
                if (res.data.status === 1) {
                    new AWN().success(`${res.data.message}`);
                    fetchData()
                } else {
                    new AWN().alert(`${res.data.message}`);
                    fetchData()
                }
            }).catch(error => {
                console.log(error);
            });

    }

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />
                <AdminProfile />
                {/* PageTitle */}

                {/*blog-grid-area start*/}
                <section className="blog-grid-area pt-150 pb-105 pt-lg-60 pb-lg-15">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title text-center mb-55">
                                    <h3 className="sect-title mb-25" data-aos="fade-up">All Subscriber</h3>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover table-bordered table-dark">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Email Id</th>
                                        <th scope="col">Date & Time</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datas.map(data => (
                                        <tr key={data.id}>
                                            <th scope="row">{data.id}</th>
                                            <td>{data.email}</td>
                                            <td>{data.created_at}</td>
                                            <td><Link onClick={event => rmsubs(data.email)} className="text-danger"><i className="bi bi-trash" aria-hidden="true"></i></Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Admin