import React, { Fragment, useState, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import AWN from "awesome-notifications"
import { Helmet } from 'react-helmet'

//componets
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Profile from './Profile'
import Spinner from '../../components/Spinner'


const WriteBlog = () => {

    const editorRef = useRef(null);
    const [img, setImg] = useState();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [Loshow, setLoShow] = useState(false);
    const handleLoClose = () => setLoShow(false);
    const handleLoShow = () => setLoShow(true);

    const onSubmit = data => {
        handleLoShow();
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("title", data.title);
        formData.append("keyword", data.keyword);
        formData.append("author", localStorage.getItem("name"));
        formData.append("user_id", localStorage.getItem("user_id"));
        formData.append("category", data.category);
        formData.append("description", editorRef.current.getContent());

        axios.post(
            `https://api.pentotest.com/new_blogs`, formData)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                    handleLoClose();
                } else {
                    new AWN().success(`${res.data.message}`);
                    handleLoClose();
                    window.location.href = '/account';
                }
            }).catch(error => {
                new AWN().alert(`Only images are allowed or maximum size 10mb.`);
                handleLoClose();
            });
    }

    function onImageChange(e) {
        const [file] = e.target.files;
        setValue("file", file);
        setImg(URL.createObjectURL(file));
    }

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />
                <Profile />

                {/*blog-grid-area start*/}
                <section className="pt-150 pb-105 pt-lg-60 pb-lg-15">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="contact-form-two pl-3 pr-3">

                                    <div className="post-title mb-50 text-center mt-0 mb-4">
                                        <h4 className="text-white">Write A Article</h4>
                                    </div>
                                    <form className="widget-form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                        <div className="row gx-4 gx-xxl-5 px-0">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-input-box">
                                                    <label className="fs-16 text-white">Choose Your Post Thumbnail: </label>
                                                    <input type="file" onChange={onImageChange} accept="image/png, image/jpeg, image/jpg" />
                                                    <input type="hidden" onChange={onImageChange} {...register('file', { required: true })} />
                                                    {errors.file &&
                                                        errors.file.type === "required" &&
                                                        <div className="text-danger">Please choose your post thumbnail.</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <img className='thumbnail-size' src={img} alt="" />
                                            </div>
                                            <div className="col-lg-8 col-md-8 col-sm-12 mb-10">
                                                <div className="form-input-box">
                                                    <label className="fs-16 text-white">Enter Unique Title: </label>
                                                    <input type="text" placeholder="Enter Unique Title*" {...register('title', { required: true })}
                                                    />
                                                    <div className="text-danger">Note: Title Should be Unique</div>
                                                    {errors.title &&
                                                        errors.title.type === "required" &&
                                                        <div className="text-danger">Please enter a Title.</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 mb-10">
                                                <div className="form-input-box">
                                                    <label className="fs-16 text-white">Enter Category: </label>
                                                    <input type="text" placeholder="Enter Category*" {...register('category', { required: true })}
                                                    />
                                                    <div className="text-danger">Ex.: Networking, Mobile, etc</div>
                                                    {errors.category &&
                                                        errors.category.type === "required" &&
                                                        <div className="text-danger">Please enter a category.</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 mb-10">
                                                <div className="form-input-box">
                                                    <label className="fs-16 text-white">Enter Keywords: </label>
                                                    <input type="text" placeholder="Enter Keywords*" {...register('keyword', { pattern: /^[a-zA-Z0-9 ]{2,500}$/ })}
                                                    />
                                                    <div className="form-danger">Ex.: wifi hackrf etc</div>
                                                    {errors.keyword &&
                                                        errors.keyword.type === "pattern" &&
                                                        <div className="text-danger">Please enter this pattern - wifi hackrf one.</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 mb-10">
                                                <Editor onInit={(evt, editor) => editorRef.current = editor}
                                                    apiKey='8cfe2vu4ntju24d0u6uxwkp7qwnxhs6zg7jymabp4vbrhnxt'
                                                    initialValue="<p>This is the initial content of the editor.</p>"
                                                    init={{
                                                        height: 500,
                                                        menubar: true,
                                                        browser_spellcheck: true,
                                                        plugins: [
                                                            'advlist autolink lists link image code charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar: 'undo redo | formatselect | image code |' +
                                                            'bold italic backcolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help | code',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                        file_picker_types: 'image',
                                                        image_file_types: 'jpg,svg,webp,gif',
                                                        paste_data_images: true,

                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4">
                                                <button type="submit" className="btn btn-primary mt-40">Save</button>
                                            </div>
                                            <div className={Loshow ? `divOne` : 'divOne d-none'}>
                                                <div className="text-center">
                                                    <Spinner />
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/*blog-grid-area end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default WriteBlog