import React, { Fragment, useState, useEffect } from 'react'
import Plyr from 'plyr-react'
import 'plyr-react/plyr.css'

import ProductReview from '../../components/form/ProductReview'

const CourseVideos = () => {

    const [pageCount, setCount] = useState(0)
    const [datas, setData] = useState([])

    const controls = [
        'play-large', 'restart', 'rewind', 'play', 'fast-forward', 'progress',
        'current-time', 'duration', 'mute', 'volume', 'captions',
        'settings', 'pip', 'airplay', 'fullscreen'
    ]

    const Content = [
        [{
            title: "About this course",
            description: "An IMSI catcher or international mobile subscriber identity-catcher is a telephone eavesdropping device used for intercepting cellular mobile phone traffic and tracking location data of mobile phone users. IMSI Catcher operates on a fake mobile phone tower between the target mobile phone and the real towers of the service provider, it is considered a man-in-the-middle (MITM) attack. The 3G or 4g wireless cellular network mutual authentication required from both the handset and the network. However, but may be able to downgrade 3G and LTE to non-LTE network services which do not require mutual authentication.",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/Cellular+Network+Hacking+Course.mp4"
        }],
        [{
            title: "What is IMSI?",
            description: "IMSI is also known as International Mobile Subscriber Identity (IMSI). IMSI is a globally unique number for each subscriber. The IMSI number consists of 15 digits, first 3 digits are Mobile Country Code (MCC), 2 digits are Mobile Network Code (MNC) and the last 10 digits are Mobile Subscriber Identification Number (MSIN). The IMSI number is stored in the Subscriber Identity Module (SIM).",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/2.mp4"
        }],
        [{
            title: "Which software we will use?",
            description: "",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/3.mp4"
        }],
        [{
            title: "IMSI catcher sniffer tool",
            description: "",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/4.mp4"
        }],
        [{
            title: "Active IMSI Catcher devices",
            description: "",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/6.mp4"
        }],
        [{
            title: "Decode the calls",
            description: "<h4>Required file: </h4> Download It: <a href='https://pentotest.com/assets/file/cfile.zip' target='_blank' rel='noreferrer'>cfile download</a>",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/7.mp4"
        }],
        [{
            title: "Fake base station",
            description: "",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/8.mp4"
        }],
        [{
            title: "How to downgrade?",
            description: "",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/9.mp4"
        }],
        [{
            title: "Trace location",
            description: "It's possible to trace location using an IMSI catcher the answer is yes. but how we can trace the location? It's a very simple process you already know how to sniff data like MNC, MCC, LAC, and CellID, etc. Go to cell2gps.com website and put some details like MNC, MCC, LAC, and CellID, etc. And location showing on the map.",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/5.mp4"
        }],
        [{
            title: "Conclusion",
            description: "I have explained everything in an easy way in this course. However, if you have any doubts or queries related to the IMSI catcher or other signal systems, you can contact me on any platform. I will always be available to assist you. If you found this course helpful, please like it and provide me with feedback.",
            videoId: "https://pentotest2.s3.us-west-1.amazonaws.com/Course/10.mp4"
        }]
    ];

    const prevbutton = () => {
        if (pageCount > 0) {
            setCount(pageCount - 1);
            setData(Content[pageCount - 1])
        }
    }
    const nextbutton = () => {
        if (pageCount < Content.length) {
            setCount(pageCount + 1);
            setData(Content[pageCount + 1])
        }
    }

    useEffect(() => {
        setData(Content[pageCount])
    }, [pageCount]);

    return (
        <Fragment>
            <div className="container">
                <div className="row align-items-center">
                    {pageCount < Content.length ?
                        <>
                            <div className="col-md-6 col-lg-6 pt-60">
                                {datas.map((data, i) => (
                                    <div key={i}>
                                        <h2 className="text-white">{data.title}</h2>
                                        <p className="text-white" dangerouslySetInnerHTML={{ __html: data.description }}></p>
                                    </div>
                                ))}
                            </div>
                            <div className="col-md-6 col-lg-6">
                                {datas.map((data, i) => (
                                    <div key={i + 100} className="embed-responsive">
                                        <Plyr options={{ controls }} source={{ type: "video", sources: [{ src: data.videoId }] }} />
                                    </div>
                                ))}
                                <div className="d-flex justify-content-between mt-3">
                                    <button className="btn btn-success" onClick={prevbutton}>Previous</button>
                                    <button className="btn btn-success" onClick={nextbutton}>Next</button>
                                </div>
                            </div>
                        </> :
                        <>
                            <div className="col-md-12 col-lg-12 pt-60">
                                {/*blog-grid-area start*/}
                                <section
                                    className="gradient-bg our-services service-bg-three pt-95 pb-55 pt-lg-55 pb-lg-15">
                                    <img
                                        className="service-shape-3c"
                                        src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-6b.svg"}
                                        alt="shape" />
                                    <div className="container">
                                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                                            <div className="col-xl-5 col-lg-6 col-md-8">
                                                <div className="section-title text-center mb-55">
                                                    <h3 className="sect-title mb-25" data-aos="fade-up">Give me feedback</h3>
                                                    <p data-aos="fade-up" data-aos-delay={100}>We would like your feedback to improve our products. What is your opinion of this course.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* FeatureFive */}
                                        <ProductReview />
                                        <button className="btn btn-success" onClick={prevbutton}>Back</button>
                                        {/* FeatureFive */}
                                    </div>
                                </section>
                                {/*blog-grid-area end*/}
                            </div>
                        </>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default CourseVideos