import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import AWN from 'awesome-notifications'
import { Helmet } from 'react-helmet'

//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import Newsletter from '../../../components/call-to-action/Newsletter'
import Footer from '../../../components/footer/Footer'


const Resetpassword = () => {

    const { email, token } = useParams();
    const { register, getValues, handleSubmit, formState: { errors }, } = useForm();

    const onSubmit = (data, e) => {

        const payload = { email: email, password: data.password, conf_password: data.conf_password, token: token }

        axios.post(
            `https://api.pentotest.com/resetpassword`, payload)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    e.target.reset();
                    new AWN().success(`${res.data.message}`);
                    window.location.href = '/login';
                }
            }).catch(error => {
                console.log(error);
            });
    }

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Forgot Password | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />

                {/* PageTitle */}
                <PageTitle title="Change Password" />
                {/* PageTitle */}

                {/*login-section start*/}
                <div className="signup-section pt-150 pb-150 pt-lg-60 pb-lg-60">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-8 col-lg-10">
                                <div className="signup-form">
                                    <h3 className="post-title mb-65 text-center">Change Password</h3>
                                    <form className="widget-form p-0" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="input-box pass mb-30">
                                            <input
                                                type="password"
                                                placeholder="Password*"
                                                {...register('password', { required: true, maxLength: 20 })}
                                            />
                                            {errors.password &&
                                                errors.password.type === "required" &&
                                                <div className="text-danger">Please enter password.</div>}
                                            {errors.password &&
                                                errors.password.type === "maxLength" &&
                                                <div className="text-danger">Please enter valid password.</div>}
                                        </div>
                                        <div className="input-box pass mb-30">
                                            <input
                                                type="password"
                                                placeholder="Confirm Password*"
                                                {...register('conf_password', {
                                                    required: true, maxLength: 20, validate: (value) => {
                                                        const { password } = getValues();
                                                        return password === value;
                                                    }
                                                })}
                                            />
                                            {errors.conf_password &&
                                                errors.conf_password.type === "required" &&
                                                <div className="text-danger">Please enter password.</div>}
                                            {errors.conf_password &&
                                                errors.conf_password.type === "maxLength" &&
                                                <div className="text-danger">Please enter valid password.</div>}
                                            {errors.conf_password &&
                                                errors.conf_password.type === "validate" &&
                                                <div className="text-danger">Passwords should match.</div>}
                                        </div>
                                        <div className="col-12 text-center">
                                            <button className="theme_btn" type="submit">Reset Password</button>
                                            <span className="divider">OR</span>
                                            <p className="text-white fw-regular mt-40 mb-10">Don’t have an account ?  <Link to="/signup" className="fw-semi">Sign up Today</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter />
                <Footer />

            </div>

        </Fragment>
    )
}

export default Resetpassword