import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import BlogsSlider from '../../../components/blog/BlogsSlider'
import AllBlogsSlider from '../../../components/blog/AllBlogsSlider'
import CategoryWidgetTwo from '../../../components/widget/CategoryWidgetTwo'
import WidgetPost from '../../../components/widget/WidgetPost'
import CategoryTag from '../../../components/widget/CategoryTag'
import Newsletter from '../../../components/call-to-action/Newsletter'
import Footer from '../../../components/footer/Footer'
import WidgetFour from '../../../components/widget/WidgetFour'

const Blogs = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>All Blogs | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <Header />
                {/* ThemeMenuThree */}

                {/* PageTitle */}
                <PageTitle title="Blog Classic" />
                {/* PageTitle */}

                {/*blog-classic-area start*/}
                <section className="blog-classic-area pt-150 pb-105 pt-lg-60 pb-lg-15">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-8">
                                {/*blog-area start*/}
                                <section className="blog-area slick-nav blog-slick pt-100 pb-100 pt-lg-55 pb-lg-60">
                                    <div className="container">
                                        <div className="row gx-4 gx-xxl-5 align-items-center">
                                            <div className="col-xl-12 col-lg-12 col-md-12" data-aos="fade-up">
                                                <div className="section-title text-center text-md-start mb-60">
                                                    <h3 className="sect-title mb-25">Latest Article</h3>
                                                </div>
                                            </div>
                                        </div>
                                        {/*blog-style-one end*/}
                                        <BlogsSlider />
                                        {/*blog-style-one end*/}
                                    </div>
                                </section>
                                {/*blog-area end*/}
                                <section className="blog-area slick-nav blog-slick pt-100 pb-100 pt-lg-55 pb-lg-60">
                                    <div className="container">
                                        <div className="row gx-4 gx-xxl-5 align-items-center">
                                            <div className="col-xl-12 col-lg-12 col-md-12" data-aos="fade-up">
                                                <div className="section-title text-center text-md-start mb-60">
                                                    <h3 className="sect-title mb-25">All Article</h3>
                                                </div>
                                            </div>
                                        </div>
                                        {/*blog-style-one end*/}
                                        <AllBlogsSlider />
                                        {/*blog-style-one end*/}
                                    </div>
                                </section>
                            </div>
                            <div className="col-lg-4">
                                <div className="right-widget-section">
                                    <div className="widget widget-categories-list mb-40">
                                        <div className="widget-title-box mb-30">
                                            <h4 className="widget-sub-title2 fs-20">Categories</h4>
                                        </div>
                                        {/* CategoryWidgetTwo start */}
                                        <CategoryWidgetTwo />
                                        {/* CategoryWidgetTwo end */}
                                    </div>
                                    <div className="widget widget-post mb-60">
                                        <div className="widget-title-box mb-30">
                                            <h4 className="widget-sub-title2 fs-20">Recent Post</h4>
                                        </div>
                                        {/* WidgetPost start */}
                                        <WidgetFour />
                                        {/* WidgetPost end */}
                                    </div>
                                    <div className="widget widget-categories-tag mb-60">
                                        <div className="widget-title-box mb-25">
                                            <h4 className="widget-sub-title2 fs-20">Tags</h4>
                                        </div>
                                        {/* CategoryTag start */}
                                        <CategoryTag />
                                        {/* CategoryTag end */}
                                    </div>
                                    <div className="widget widget-post mb-60">
                                        <div className="widget-title-box mb-30">
                                            <h4 className="widget-sub-title2 fs-20">Popular Post</h4>
                                        </div>
                                        <ul className="post-list">
                                            {/* WidgetPost start */}
                                            <WidgetPost />
                                            {/* WidgetPost end */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/*blog-classic-area end*/}

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Blogs