import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import AWN from 'awesome-notifications'
import ReCaptcha from '@matt-block/react-recaptcha-v2'
import Spinner from '../../components/Spinner'

const Login = () => {

    const { register, handleSubmit, formState: { errors }, } = useForm()
    const [disable, setDisable] = useState(true);
    const [gatCaptcha, setCaptcha] = useState()

    const [Loginshow, setLoginShow] = useState(false)
    const handleLoginClose = () => setLoginShow(false)
    const handleLoginShow = () => setLoginShow(true)

    const onSubmit = (data, e) => {
        handleLoginShow()
        const payload = { email: data.email, password: data.password, captcha: gatCaptcha }
        axios.post(
            `https://api.pentotest.com/login`, payload)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`)
                    handleLoginClose()
                } else if (res.data.status === 3) {
                    new AWN().alert(`${res.data.message}`)
                    handleLoginClose()
                    window.location.reload()
                } else if (res.data.status === 400) {
                    new AWN().alert(`${res.data.message}`)
                    handleLoginClose()
                } else {
                    handleLoginClose()
                    e.target.reset()
                    localStorage.setItem("user_id", res.data.data[0].user_id)
                    localStorage.setItem("name", res.data.data[0].name)
                    localStorage.setItem("description", res.data.data[0].description)
                    localStorage.setItem("user_image", res.data.data[0].user_image)
                    localStorage.setItem("token", res.data.data[0].token)
                    localStorage.setItem("role", res.data.data[0].role)
                    if (res.data.data[0].role === 'admin') {
                        window.location.href = '/admin'
                    } else {
                        window.location.href = '/account'
                    }
                }
            }).catch(error => {
                console.log(error)
            });
    }

    useEffect(() => {
        localStorage.clear()
    }, []);

    function onChange(value) {
        if (value !== null) {
            setCaptcha(value);
            setDisable(false);
        } else {
            setDisable(true);
        }
    }

    return (
        <Fragment>
            <form className="widget-form p-0" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-box mail mb-30">
                    <input
                        type="email"
                        placeholder="Email Address*"
                        {...register('email', { required: true, maxLength: 30, pattern: /^\S+@\S+$/i })}
                    />
                    {errors.email &&
                        errors.email.type === "required" &&
                        <div className="text-danger">Please enter email address.</div>}
                    {errors.email &&
                        errors.email.type === "maxLength" &&
                        <div className="text-danger">Please enter valid email address.</div>}
                    {errors.email &&
                        errors.email.type === "pattern" &&
                        <div className="text-danger">Please enter valid email address.</div>}
                </div>
                <div className="input-box pass mb-30">
                    <input
                        type="password"
                        placeholder="Password*"
                        {...register('password', { required: true, maxLength: 20 })}
                    />
                    {errors.password &&
                        errors.password.type === "required" &&
                        <div className="text-danger">Please enter password.</div>}
                    {errors.password &&
                        errors.password.type === "maxLength" &&
                        <div className="text-danger">Please enter valid password.</div>}
                </div>
                <div className="col-lg-12 text-center mb-20">
                    <ReCaptcha
                        siteKey="6Lcd74MkAAAAAFzbtAGPRmgoP-T9cH6YaXAsaP_q"
                        theme="light"
                        size="normal"
                        onSuccess={(captcha) => onChange(captcha)}
                        onExpire={() => onChange(null)}
                        onError={() => onChange(null)}
                    />
                </div>
                <div className="col-12 text-center">
                    <button className="theme_btn" type="submit" disabled={disable}>Log In Now</button>
                    <div className={Loginshow ? `divOne` : 'divOne d-none'}>
                        <div className="text-center">
                            <Spinner />
                        </div>
                    </div>
                    <span className="divider">OR</span>
                    <p className="text-white fw-regular mt-40 mb-10">Don’t have an account ?  <Link to="/signup" className="fw-semi">Sign up Today</Link></p>
                    <Link to="/forgot-password" className="fw-semi text-white fw-semi">Forgot password</Link>
                </div>
            </form>
        </Fragment>
    )
}

export default Login