import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import ModalVideos from '../../../components/modal-video/ModalVideos'
import Newsletter from '../../../components/call-to-action/Newsletter'
import Footer from '../../../components/footer/Footer'
import CaseProductSlider from '../../../components/case-study/CaseProductSlider'

const AllProduct = () => {
    const [open, setOpen] = useState(false);
    const OpenModal = () => {
        setOpen(!open)
    }
    return (
        <Fragment>
            <ModalVideos isOpen={open} onClick={OpenModal} />
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>All Courses | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <Header />
                {/* ThemeMenuThree */}

                {/* PageTitle */}
                <PageTitle title="Cellular Network Hacking Course" />
                {/* PageTitle */}

                {/*techy-team start*/}
                <section className="gradient-bg techy-team pt-210 pt-lg-180 pt-sm-60 pb-100 pb-lg-55">
                    <img className="team-shape shape-1b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-3b.svg"} alt="shape" />
                    <img className="team-shape shape-2b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-4b.svg"} alt="shape" />
                    <img className="team-shape shape-3b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-2.svg"} alt="shape" />
                    <img className="team-shape shape-4b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-5b.svg"} alt="shape" />
                    <img className="team-shape shape-5b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-5b.svg"} alt="shape" />
                    <img className="team-shape shape-6b" src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-6b.svg"} alt="shape" />
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb-55">
                                    <h6 className="sub-title mb-20">All Course</h6>
                                    <h2 className="sect-title">See Our Latest Courses</h2>
                                </div>
                            </div>
                        </div>
                        {/* TeamOne */}
                        <CaseProductSlider />
                        {/* TeamOne */}
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12 text-center pt-15" data-aos="fade-up" data-aos-delay={200}>
                                <Link className="theme_btn" to="/courses">All Course</Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/*techy-team end*/}

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default AllProduct