import React, { Fragment } from 'react';
import axios from 'axios';
import AWN from "awesome-notifications";
import { useForm } from 'react-hook-form';

const FeebackFormEmp = () => {

    const { register, handleSubmit, formState: { errors }, } = useForm();

    const onSubmit = dat => {

        const data = { rating: dat.rating, feedback: dat.feedback, name: localStorage.getItem('name'), image: localStorage.getItem("user_image"), user_id: localStorage.getItem("user_id") }

        axios.post(
            `https://api.pentotest.com/feedback`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    new AWN().success(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });
    }

    return (

        <Fragment>
            <form className="widget-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-md-12 mb-20">
                    <div className="text-center mb-5">
                        <div className="rating">
                            <input type="radio" name="rating" value="5" id="5" {...register('rating')} /><label htmlFor="5">☆</label>
                            <input type="radio" name="rating" value="4" id="4" {...register('rating')} /><label htmlFor="4">☆</label>
                            <input type="radio" name="rating" value="3" id="3" {...register('rating')} /><label htmlFor="3">☆</label>
                            <input type="radio" name="rating" value="2" id="2" {...register('rating')} /><label htmlFor="2">☆</label>
                            <input type="radio" name="rating" value="1" id="1" {...register('rating')} /><label htmlFor="1">☆</label>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-20">
                    <div className="form-input-box">
                        <label className="fs-16 text-white">Comments: </label>
                        <textarea placeholder="Write Message*" {...register('feedback', { required: true, maxLength: 250 })} />
                        {errors.feedback &&
                            errors.feedback.type === "required" &&
                            <div className="text-danger">Give me feedback.</div>}
                        {errors.feedback &&
                            errors.feedback.type === "maxLength" &&
                            <div className="text-danger">Please enter valid message.</div>}
                    </div>
                </div>
                <div className="col-12">
                    <button className="theme_btn">Submit Now</button>
                </div>
            </form>
        </Fragment>

    )
}

export default FeebackFormEmp