import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios"
import AWN from 'awesome-notifications'
import Cookies from 'universal-cookie'


const caseContent = [
    {
        course_img: '/assets/img/course/imsi-course.gif',
        course_type: 'IMSI Catcher',
        course_tittle: 'Cellular Network Hacking Course',
        course_slug: 'cellular-network-hacking-course'
    }

];

const ProductSlider = () => {

    const [courseDatas, courseData] = useState([]);
    const [dataf, setUsers] = useState();

    const cookies = new Cookies();
    const Addtocart = () => {
        const data = { product_id: "6331a3a4a7d4cd4ee1d504a5", product_name: "Cellular Network Hacking Course", product_price: 30 }
        cookies.set('Cart', data);
        window.location.href = '/cart';
    };

    useEffect(() => {

        const data1 = { user_id: localStorage.getItem("user_id") };
        axios.post(`https://api.pentotest.com/account`, data1)
            .then(res => {
                if (res.data.status === 1) {
                    setUsers(res.data.data[0].product_status);
                    // handleLoClose();
                } else {
                    new AWN().alert(`${res.data.message}`);
                    //  handleLoClose();
                }
            }).catch(error => {
                console.log(error);
            });

        axios.post(`https://api.pentotest.com/fetchreviewav`)
            .then(res => {
                if (res.data.status === 1) {
                    courseData(res.data.data);
                } else {
                    new AWN().alert("Server error.");
                }
            }).catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <Fragment>
            <div className="row gx-4 gx-xxl-5 justify-content-center">
                {caseContent.map((val, i) => (
                    <div key={i} className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                        <div className="team-style-one text-center mb-45">
                            <Link to={`/course/${val.course_slug}`}><img src={process.env.PUBLIC_URL + val.course_img} alt={val.course_tittle} /></Link>
                            <div className="team-content">
                                <p>{val.course_type}</p>
                                <h3 className="sect-title-two">
                                    <Link to={`/course/${val.course_slug}`}>{val.course_tittle}</Link>
                                </h3>
                                {courseDatas.map((data, i) => (
                                    <div key={data.id}>
                                        <div className="text-center ratings">
                                            {data.avtwodi < 1 ? <>
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                            </> : 1 <= data.avtwodi && data.avtwodi <= 1.4 ? <>
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                            </> : 1.5 <= data.avtwodi && data.avtwodi < 2 ? <>
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-half" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                            </> : 2 <= data.avtwodi && data.avtwodi <= 2.4 ? <>
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                            </> : 2.5 <= data.avtwodi && data.avtwodi < 3 ? <>
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-half" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                            </> : 3 <= data.avtwodi && data.avtwodi <= 3.4 ? <>
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star" />
                                                <i className="bi bi-star" />
                                            </> : 3.5 <= data.avtwodi && data.avtwodi < 4 ? <>
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-half" />
                                                <i className="bi bi-star" />
                                            </> : 4 <= data.avtwodi && data.avtwodi <= 4.4 ? <>
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star" />
                                            </> : 4.5 <= data.avtwodi && data.avtwodi < 5 ? <>
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-half" />
                                            </> : <>
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                                <i className="bi bi-star-fill" />
                                            </>
                                            }
                                            <div className="text-center text-black">
                                                (<i className="fa fa-users" aria-hidden="true"></i>{data.totalreviews})
                                            </div>
                                        </div>

                                    </div>
                                ))}
                                <div className="row">
                                    {!dataf ?
                                        <>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="text-start pl-20">
                                                    <div className="text-black"><h4>$30</h4><del className="text-black mr-2">$200</del>(85.0% off)</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="text-end pr-20">
                                                    <Link onClick={event => Addtocart()} type="button" className="btn btn-outline-success"><b className='text-primary'>ADD TO CART<i className="fa fa-cart-plus" aria-hidden="true"></i></b></Link>
                                                    <p className='text-success'>In Stock</p>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="col-lg-12 col-md-12 text-center">
                                                <Link to={`/mycourse/${val.course_slug}`} type="button" className="btn btn-outline-success"><b className='text-primary'><i className="bi bi-eye" aria-hidden="true"></i>View</b></Link>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                ))}
            </div>
        </Fragment>
    )
}

export default ProductSlider