import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import AWN from 'awesome-notifications'
import { Helmet } from 'react-helmet'

//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import Newsletter from '../../../components/call-to-action/Newsletter'
import Footer from '../../../components/footer/Footer'
import Spinner from '../../../components/Spinner'


const Forgotpassword = () => {

    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [Loginshow, setLoginShow] = useState(false)
    const handleLoginClose = () => setLoginShow(false)
    const handleLoginShow = () => setLoginShow(true)

    const onSubmit = (data, e) => {
        handleLoginShow()
        axios.post(
            `https://api.pentotest.com/forgotpassword`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                    handleLoginClose()
                } else {
                    e.target.reset();
                    new AWN().success(`${res.data.message}`);
                    handleLoginClose()
                }
            }).catch(error => {
                console.log(error);
            });
    }

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Forgot Password | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />

                {/* PageTitle */}
                <PageTitle title="Forgot Password" />
                {/* PageTitle */}

                {/*login-section start*/}
                <div className="signup-section pt-150 pb-150 pt-lg-60 pb-lg-60">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-8 col-lg-10">
                                <div className="signup-form">
                                    <h3 className="post-title mb-65 text-center">Reset Password</h3>
                                    <p className="text-center mt-20 mb-20">Lost your password? Please enter your email address. You will receive a link to create a new password via email.</p>
                                    <form className="widget-form p-0" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="input-box mail mb-30">
                                            <input
                                                type="email"
                                                placeholder="Email Address*"
                                                {...register('email', { required: true, maxLength: 30, pattern: /^\S+@\S+$/i })}
                                            />
                                            {errors.email &&
                                                errors.email.type === "required" &&
                                                <div className="text-danger">Please enter email address.</div>}
                                            {errors.email &&
                                                errors.email.type === "maxLength" &&
                                                <div className="text-danger">Please enter valid email address.</div>}
                                            {errors.email &&
                                                errors.email.type === "pattern" &&
                                                <div className="text-danger">Please enter valid email address.</div>}
                                        </div>
                                        <div className="col-12 text-center">
                                            <button className="theme_btn" type="submit">Reset Passwor</button>
                                            <div className={Loginshow ? `divOne` : 'divOne d-none'}>
                                                <div className="text-center">
                                                    <Spinner />
                                                </div>
                                            </div>
                                            <span className="divider">OR</span>
                                            <p className="text-white fw-regular mt-40 mb-10">Don’t have an account ?  <Link to="/signup" className="fw-semi">Sign up Today</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter />
                <Footer />

            </div>

        </Fragment>
    )
}

export default Forgotpassword