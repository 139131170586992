import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ScrollTopBehavior from '../components/ScrollTopBehavior'

import Protected from './Protected'
import AdminProtected from './AdminProtected'

//Home Pages
import Home from '../views/home-pages/Home'

//inner-pages
//about-page
import About from '../views/inner-pages/about/About'

//services-pages
import Services from '../views/inner-pages/services/Services'
import ServicesDetails from '../views/inner-pages/services/ServicesDetails'

//team-pages
import MyTeam from '../views/inner-pages/team/MyTeam'
import TeamDetails from '../views/inner-pages/team/TeamDetails'

//pages
import Login from '../views/inner-pages/pages/Login'
import Signup from '../views/inner-pages/pages/Signup'
import Sitemap from '../views/inner-pages/pages/Sitemap'
import Termsandconditions from '../views/inner-pages/pages/Termsandconditions'
import Privacypolicy from '../views/inner-pages/pages/Privacypolicy'
import RefundsAndCancellations from '../views/inner-pages/pages/refunds-and-cancellations'
import Forgotpassword from '../views/inner-pages/pages/Forgotpassword'
import Resetpassword from '../views/inner-pages/pages/Resetpassword'

//blog-pages
import Blogs from '../views/inner-pages/blog/Blogs'
import BlogDetails from '../views/inner-pages/blog/BlogDetails'

//conatct-page
import Contact from '../views/inner-pages/pages/Contact'

//courses
import AllCourse from '../views/inner-pages/product/AllCourse'
import Course from '../views/inner-pages/product/Course'
import Cart from '../views/inner-pages/pages/Cart'

//accound
import Account from '../views/account/Account'
import WriteBlog from '../views/account/WriteBlog'
import Feedback from '../views/account/Feedback'
import MyProducts from '../views/account/MyProducts'
import ViewProduct from '../views/account/ViewCourse'
import EditBlog from '../views/account/EditBlog'
import SendMail from '../views/account/SendMail'
import DeleteAccount from '../views/account/DeleteAccount'
import Admin from '../views/account/Admin'
import AdminAllUsers from '../views/account/AdminAllUsers'

//error-404
import NotFound from '../views/NotFound'


const AppRouter = () => {
    return (
        <>
            <ScrollTopBehavior />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/team" element={<MyTeam />} />
                <Route path="/team-details" element={<TeamDetails />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blogs/:slug" element={<BlogDetails />} />
                <Route path="/courses" element={<AllCourse />} />
                <Route path="/course/:slug" element={<Course />} />
                <Route exact path='/privacy-policy' element={<Privacypolicy />}></Route>
                <Route exact path='/terms-and-conditions' element={<Termsandconditions />}></Route>
                <Route exact path='/refunds-and-cancellations' element={<RefundsAndCancellations />}></Route>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/services" element={<Services />} />
                <Route path="/service-details" element={<ServicesDetails />} />
                <Route exact path='/forgot-password' element={<Forgotpassword />}></Route>
                <Route exact path='/reset-password/:email/reset/:token' element={<Resetpassword />}></Route>

                <Route path="/cart" element={<Cart />} />
                <Route path="/sitemap" element={<Sitemap />} />

                <Route exact path='/account' element={
                    <Protected >
                        <Account />
                    </Protected>}>
                </Route>
                <Route exact path='/account/write' element={
                    <Protected >
                        <WriteBlog />
                    </Protected>}>
                </Route>
                <Route exact path='/account/my-products' element={
                    <Protected >
                        <MyProducts />
                    </Protected>}>
                </Route>
                <Route exact path='/mycourse/:slug' element={
                    <Protected >
                        <ViewProduct />
                    </Protected>}>
                </Route>
                <Route exact path='/account/edit/:blog_id/:slug' element={
                    <Protected >
                        <EditBlog />
                    </Protected>}>
                </Route>
                <Route exact path='/account/feedback' element={
                    <Protected >
                        <Feedback />
                    </Protected>}>
                </Route>
                <Route exact path='/account/sendmail' element={
                    <Protected >
                        <SendMail />
                    </Protected>}>
                </Route>
                <Route exact path='/account/account-delete' element={
                    <Protected >
                        <DeleteAccount />
                    </Protected>}>
                </Route>
                <Route exact path='/admin' element={
                    <AdminProtected>
                        <Admin />
                    </AdminProtected>}>
                </Route>
                <Route exact path='/admin/allusers' element={
                    <AdminProtected>
                        <AdminAllUsers />
                    </AdminProtected>}>
                </Route>

                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    )
}

export default AppRouter