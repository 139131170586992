import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import AWN from 'awesome-notifications'
import { Button, Modal } from 'react-bootstrap'

import LoginModal from '../../components/modal/LoginModal'

const BlogAuthor = () => {

    const { slug } = useParams();
    const [datas, setUsers] = useState([]);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    function reload() {
        const data = { slug: slug }
        axios.post(`https://api.pentotest.com/viewblogs`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setUsers(res.data.data)
                }
            }).catch(error => {
                console.log(error);
            });
    }

    const followClick = (following) => {
        const data = { followers: localStorage.getItem("user_id"), following: following }
        axios.post(`https://api.pentotest.com/follow`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    new AWN().success(`${res.data.message}`);
                    reload();
                }
            }).catch(error => {
                console.log(error);
            });

    }

    useEffect(() => {
        const data = { slug: slug }
        axios.post(`https://api.pentotest.com/viewblogs`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setUsers(res.data.data);
                }
            }).catch(error => {
                console.log(error);
            });
    }, [slug]);

    const loginModel = () => {
        handleShow1()
    }

    return (
        <Fragment>
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header>
                    <Modal.Title><b className="text-success">Welcome Back!</b></Modal.Title>
                    <Button variant="link" onClick={handleClose1}><i className="fa fa-times text-dark" aria-hidden="true"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    <LoginModal />
                </Modal.Body>
            </Modal>
            {datas.map(data => (
                <div key={7} className="row gx-4 gx-xxl-5 align-items-center author-profile mx-md-0 mt-60">
                    <div className="col-lg-9 col-md-9">
                        <div
                            className="blog-author d-md-flex align-items-center text-center text-md-start mb-30">
                            <div className="author-avatar mb-4 mb-md-0 me-md-3">
                                <img className="dpavatar" src={data.user_image === 'default_image.png' ? process.env.PUBLIC_URL + '/assets/images/default_image.png' : data.user_image} alt={data.name} />
                            </div>
                            <div className="author-info ms-xl-3">
                                <h3 className="author-name text-capitalize">{data.name}</h3>
                                <p className="text-white">{data.desc}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 text-center text-md-end">
                        <div className="team-social-three social-style-five mb-30 text-white">
                            {data.followers} Followers
                            {localStorage.getItem('user_id') ?
                                <button type="submit" className="btn btn-success rounded-pill" onClick={event => followClick(data.user_id)}>Follow</button>
                                :
                                <button type="button" className="btn btn-success rounded-pill" onClick={loginModel}>Follow</button>}
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>
    )
}

export default BlogAuthor