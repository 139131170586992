
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import AOS from 'aos';
import "aos/dist/aos.css"
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './router/AppRouter'
import ScrollToTop from './components/ScrollToTop'

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords"
          content="pentotest, pentotest.com, pantotest, pantotest.com, shahrukh khan, pantotest shahrukh, shahrukh, sharukh, pan to test , pen to test, pen2test, pantoest, pan2test, pantotest, pantotest.com, pinterest, bentotest, pantobest, pan test, pinterest shahrukh" />
        <meta name="description"
          content="Welcome to our cyber security and IT services website, where we provide the latest information and insights on cyber threats, privacy, and best practices for keeping your online presence safe and secure. Our team of experts offers top-notch IT services, including website design, network security, and cloud solutions to help businesses and individuals stay protected in the ever-evolving digital landscape. With a focus on quality content and exceptional customer service, we strive to be your go-to source for all things cyber security and IT. Explore our blog for the latest industry news and contact us to learn how we can help protect your digital assets." />
        <meta property="og:site_name" content="Sinco" />
        <meta property="og:url" content="https://themeforest.net/user/hamina-themes/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Welcome To The Pentotest.com | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder" />
      </Helmet>
      <ScrollToTop />
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
