import React, { Fragment, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import AWN from 'awesome-notifications'

//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import NewsletterTwo from '../../../components/call-to-action/NewsletterTwo'
import Footer from '../../../components/footer/Footer'

const Sitemap = () => {

    const [datas, setUsers] = useState([]);

    useEffect(() => {
        axios.post(`https://api.pentotest.com/fetchsiteblogs`)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setUsers(res.data.data);
                }
            }).catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Sitemap | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <Header />
                {/* ThemeMenuThree */}

                {/* PageTitle */}
                <PageTitle title="Site Map" />
                {/* PageTitle */}

                {/*blog-classic-area start*/}
                <section className="blog-classic-area pt-50 pb-50 pt-lg-0 pb-lg-15">
                    <div className="container">
                        <div className="text-white">
                            {/* BlogStyleFour start */}
                            <div className="row pt-40">
                                <div className="col-12 col-md-4">
                                    <h5 className="text-white">Home Page</h5>
                                    <ul className="text-list list-none">
                                        <li><a href="https://www.pentotest.com">Home Page</a></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h5 className="text-white">About US</h5>
                                    <ul className="text-list list-none">
                                        <li><a href="https://www.pentotest.com/about">About Us</a></li>
                                        <li><a href="https://www.pentotest.com/team-details">Team Details</a></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h5 className="text-white">Service</h5>
                                    <ul className="text-list list-none">
                                        <li><a href="https://www.pentotest.com/service">Service</a></li>
                                        <li><a href="https://www.pentotest.com/service-details">Service Details</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row pt-40">
                                <div className="col-12 col-md-4">
                                    <h5 className="text-white">Contact Us</h5>
                                    <ul className="text-list list-none">
                                        <li><a href="https://www.pentotest.com/contact">Contact Us</a></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h5 className="text-white">SignUp</h5>
                                    <ul className="text-list list-none">
                                        <li><a href="https://www.pentotest.com/signup">SignUp</a></li>
                                        <li><a href="https://www.pentotest.com/login">Login</a></li>
                                        <li><a href="https://www.pentotest.com/forgot-password">Forgot Password</a></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-4">
                                    <h5 className="text-white">Privacy Policy</h5>
                                    <ul className="text-list list-none">
                                        <li><a href="https://www.pentotest.com/privacy-policy">Privacy Policy</a></li>
                                        <li><a href="https://www.pentotest.com/terms-and-conditions">Terms and Conditions</a></li>
                                        <li><a href="https://www.pentotest.com/refunds-and-cancellations">Refunds And Cancellations</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row pt-40">
                                <div className="col-12 col-md-4">
                                    <h5 className="text-white">All Blogs</h5>
                                    <ul className="text-list list-none">
                                        <li><a href="https://www.pentotest.com/blogs">All Category Blog</a></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-md-8">
                                    <h5 className="text-white">All Course</h5>
                                    <ul className="text-list list-none">
                                        <li>
                                            <a href={`https://www.pentotest.com/course/cellular-network-hacking-course`}>
                                                Cellular Network Hacking Course
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row pt-40 pb-40">
                                <div className="col-12 col-md-12">
                                    <h5 className="text-white">All Blogs Pages</h5>
                                    <ul className="text-list list-none">
                                        {datas.map(data => (
                                            <li key={data.blog_id}>
                                                <a href={`https://www.pentotest.com/blogs/${data.slug}`}>
                                                    {data.title}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            {/* BlogStyleFour end */}
                        </div>
                    </div>
                </section>
                {/*blog-classic-area end*/}

                {/*techy-newsletter start*/}
                <NewsletterTwo />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Sitemap