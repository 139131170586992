
import React, { Fragment, useState } from 'react'
import Modal from 'react-modal'
import axios from 'axios'
import AWN from 'awesome-notifications'
import { Link } from 'react-router-dom'


const SearchModal = (props) => {
    const { isOpen, onClick } = props;

    const [searchData, setsearchData] = useState([]);
    const [data, setSearch] = useState("");

    const setQuery = (svalue) => {
        const data = { search: svalue }
        axios.post(`https://api.pentotest.com/search`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setsearchData(res.data.data)
                    setSearch(svalue)
                }
            }).catch(error => {
                console.log(error);
            });
    }

    return (
        <Fragment>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClick}
                className="offcanvas-tops"
                contentLabel="Example Modal"
            >
                <div className="search-wrap">
                    <button onClick={onClick}><i className="bi bi-x-square"></i></button>
                    <div className="search-form-input">
                        <input type="text" onChange={event => setQuery(event.target.value)} className="main-search-input" placeholder="Search Your Keyword..." />
                    </div>
                    <div className="container">
                        <div className="row text-white text-center">
                            {searchData.length < 1 ?
                                <h5 className="text-white text-center">Your search - {data} - did not match any documents.</h5>
                                :
                                searchData.map(data => (
                                    <div key={data.blog_id} className="col-lg-12 col-md-12 col-sm-12">
                                        <h3 className="text-capitalize text-white mt-10">
                                            <Link to={`/blogs/${data.slug}`}>
                                                {data.title}
                                            </Link>
                                        </h3> 
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

export default SearchModal