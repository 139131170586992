import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Company = [
  {
    name: 'About Us',
    routerPath: '/about'
  },
  {
    name: 'Team',
    routerPath: '/team'
  },
  {
    name: 'Team Details',
    routerPath: '/team-details'
  },

];

const Services = [
  {
    name: 'Services',
    routerPath: '/services'
  },
  {
    name: 'Service Details',
    routerPath: '/service-details'
  },
  {
    name: 'Contact',
    routerPath: '/contact'
  },
];

const Blogs = [
  {
    name: 'All Category Blog',
    routerPath: '/blogs'
  },
];

const Courses = [
  {
    name: 'Courses',
    routerPath: '/courses'
  },
];


const ThemeMainMenu = () => {
  return (
    <Fragment>
      <ul className="navbar-nav main-navbar mb-2 mb-lg-0 ps-1">
        <li className="nav-item">
          <Link className="nav-link" to="/">Home</Link>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link" href={() => false} id="navbarDropdownTwo">
            Company
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownTwo">
            {Company.map((val, i) => (
              <li key={i}><Link className="dropdown-item" to={val.routerPath}>{val.name}</Link></li>
            ))}
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href={() => false} id="navbarDropdown">
            Services
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            {Services.map((val, i) => (
              <li key={i}><Link className="dropdown-item" to={val.routerPath}>{val.name}</Link></li>
            ))}
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href={() => false} id="navbarDropdownThree">
            Blogs
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownThree">
            {Blogs.map((val, i) => (
              <li key={i}><Link className="dropdown-item" to={val.routerPath}>{val.name}</Link></li>
            ))}
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href={() => false} id="navbarDropdownThree">
            Courses
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownThree">
            {Courses.map((val, i) => (
              <li key={i}><Link className="dropdown-item" to={val.routerPath}>{val.name}</Link></li>
            ))}
          </ul>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact">contact</Link>
        </li>
      </ul>
    </Fragment>
  )
}

export default ThemeMainMenu