import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import 'react-tabs/style/react-tabs.css'
import axios from 'axios'
import AWN from 'awesome-notifications'
import Dropdown from 'react-bootstrap/Dropdown'

const BlogStyle = () => {

    const [BlogContent, setUsers] = useState([]);

    const fetchData = () => {
        const data = { user_id: localStorage.getItem("user_id") }
        axios.post(`https://api.pentotest.com/fetch_blogs_by_user`, data)
            .then(res => {

                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setUsers(res.data.data)
                }
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchData()
    }, [])

    const publicClick = (blog_id) => {

        const data = { blog_id: blog_id }
        axios.post(`https://api.pentotest.com/public`, data)
            .then(res => {

                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    fetchData()
                    new AWN().success(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });
    };

    const privateClick = (blog_id) => {

        const data = { blog_id: blog_id }
        axios.post(`https://api.pentotest.com/private`, data)
            .then(res => {

                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    fetchData()
                    new AWN().warning(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });
    };

    return (

        <Fragment>
            {BlogContent.length < 1 ? <h4 className="mx-3 text-info">No blogs available, please write a blog.</h4> :
                <div className="row gx-4 gx-xxl-5">
                    {BlogContent.map((data, i) => (
                        <div key={i} className="col-lg-4 col-md-6">
                            <div className="blog-style-two mb-45">
                                <div className="blog-thumb">
                                    <img className="w-100 image-size" src={data.file} alt="img" />
                                </div>
                                <div className="blog-content">
                                    <div className="blog-tag pb-10">
                                        {data.category}
                                    </div>
                                    <h3>
                                        <Link className="blog-title" to={`/blogs/${data.slug}`}>{data.title}</Link>
                                    </h3>
                                    <div className="row blog-meta">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <i className="bi bi-clock" />{data.created_at}
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    {data.active === true ?
                                                        data.status === true ?
                                                            <small className="float-right text-white">Live</small>
                                                            :
                                                            <small className="float-right text-warning">Private</small>
                                                        :
                                                        <small className="float-right text-danger">Blocked By Admin</small>}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdowns-menu">
                                                    <Dropdown.Item><Link className="text-primary"
                                                        to={`/account/edit/${data.blog_id}/${data.slug}`}>Edit <i
                                                            className="fa fa-pencil" aria-hidden="true"></i></Link></Dropdown.Item>
                                                    <Dropdown.Item><Link className="text-success"
                                                        href={() => false} onClick={event => publicClick(data.blog_id)}>Public <i
                                                            className="fa fa-play"
                                                            aria-hidden="true"></i></Link></Dropdown.Item>
                                                    <Dropdown.Item><Link className="text-warning"
                                                        href={() => false} onClick={event => privateClick(data.blog_id)}>Private <i
                                                            className="fa fa-pause"
                                                            aria-hidden="true"></i></Link></Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        </Fragment>
    )
}

export default BlogStyle


