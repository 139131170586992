import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import AWN from 'awesome-notifications'

//componets
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import AdminProfile from './AdminProfile';


const AdminAllUsers = () => {

    const [datas, setUsers] = useState([]);

    const fetchData = () => {
        const data = { user_id: localStorage.getItem("user_id"), token: localStorage.getItem("token") }
        axios.post(`https://api.pentotest.com/signup_users/651b135223f0a1fc2ce2e6a6`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setUsers(res.data.data)
                }
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchData()
    }, [])

    const ActionBloFun = (user_email, status) => {
        const data = { user_email: user_email, status: status, user_id: localStorage.getItem("user_id"), token: localStorage.getItem("token") }
        axios.post(`https://api.pentotest.com/users/blockbyadmin/651b135223f0a1fc2ce2e6a6`, data)
            .then(res => {
                if (res.data.status === 1) {
                    new AWN().success(`${res.data.message}`);
                    fetchData()
                } else {
                    new AWN().alert(`${res.data.message}`);
                    fetchData()
                }
            }).catch(error => {
                console.log(error);
            });

    }

    const ActionProFun = (user_email, status) => {
        const data = { user_email: user_email, status: status, user_id: localStorage.getItem("user_id"), token: localStorage.getItem("token") }
        axios.post(`https://api.pentotest.com/users/activateproduct/651b135223f0a1fc2ce2e6a6`, data)
            .then(res => {
                if (res.data.status === 1) {
                    new AWN().success(`${res.data.message}`);
                    fetchData()
                } else {
                    new AWN().alert(`${res.data.message}`);
                    fetchData()
                }
            }).catch(error => {
                console.log(error);
            });

    }

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />
                <AdminProfile />
                {/* PageTitle */}

                {/*blog-grid-area start*/}
                <section className="gradient-bg our-services service-bg-three pt-95 pb-55 pt-lg-55 pb-lg-15">
                    <img className="service-shape-3c" src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-6b.svg"} alt="shape" />
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title text-center mb-55">
                                    <h3 className="sect-title mb-25" data-aos="fade-up">All Users</h3>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover table-bordered table-dark">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email Id</th>
                                        <th scope="col">IP Address</th>
                                        <th scope="col">Country</th>
                                        <th scope="col">By Admin Account Status</th>
                                        <th scope="col">By Self Delete Account Status</th>
                                        <th scope="col">Product Status</th>
                                        <th scope="col">Updated At</th>
                                        <th scope="col">Created At</th>
                                        <th scope="col">User Agent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datas.map(data => (
                                        <tr key={data.id}>
                                            <th scope="row">{data.id}</th>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>
                                            <td>{data.ip}</td>
                                            <td>{data.ipcountry}</td>
                                            {data.status === 1 ?
                                                <td><button type="button" className="btn btn-success rounded-pill" onClick={event => ActionBloFun(data.email, 0)}>Active</button></td> :
                                                <td><button type="button" className="btn btn-danger rounded-pill" onClick={event => ActionBloFun(data.email, 1)}>Blocked</button></td>
                                            }
                                            {data.delete_status === false ?
                                                <td><button type="button" className="btn btn-success rounded-pill">Active</button></td> :
                                                <td><button type="button" className="btn btn-danger rounded-pill">Deleted</button></td>
                                            }
                                            {data.product_status === true ?
                                                <td><button type="button" className="btn btn-success rounded-pill" onClick={event => ActionProFun(data.email, false)}>Active</button></td> :
                                                <td><button type="button" className="btn btn-danger rounded-pill" onClick={event => ActionProFun(data.email, true)}>Inactive</button></td>
                                            }
                                            <td>{data.updated_at}</td>
                                            <td>{data.created_at}</td>
                                            <td>{data.user}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default AdminAllUsers