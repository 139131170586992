import React, { Fragment, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import AWN from "awesome-notifications"
import { Helmet } from 'react-helmet'

//componets
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Profile from './Profile'

const SendMail = () => {

    const editorRef = useRef(null);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data, e) => {

        const payload = { subject: data.subject, description: editorRef.current.getContent() }

        axios.post(
            `https://api.pentotest.com/sendmail`, payload)
            .then(res => {
                if (res.data.status === 1) {
                    e.target.reset();
                    new AWN().success(`${res.data.message}`);
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });
    }

    const mailcontent = `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="x-apple-disable-message-reformatting">
    <title>WWW.PENTOTEST.COM</title>
    <link href="https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i" rel="stylesheet">
    <style>
        html,
        body {
            margin: 0 auto !important;
            padding: 0 !important;
            height: 100% !important;
            width: 100% !important;
            background: #f1f1f1;
        }

        * {
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
        }

        div[style*="margin: 16px 0"] {
            margin: 0 !important;
        }

        table,
        td {
            mso-table-lspace: 0pt !important;
            mso-table-rspace: 0pt !important;
        }

        table {
            border-spacing: 0 !important;
            border-collapse: collapse !important;
            table-layout: fixed !important;
            margin: 0 auto !important;
        }

        img {
            -ms-interpolation-mode: bicubic;
        }

        a {
            text-decoration: none;
        }

        *[x-apple-data-detectors],
        .unstyle-auto-detected-links *,
        .aBn {
            border-bottom: 0 !important;
            cursor: default !important;
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        .a6S {
            display: none !important;
            opacity: 0.01 !important;
        }

        .im {
            color: inherit !important;
        }

        img.g-img+div {
            display: none !important;
        }

        @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
            u~div .email-container {
                min-width: 320px !important;
            }
        }

        @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
            u~div .email-container {
                min-width: 375px !important;
            }
        }

        @media only screen and (min-device-width: 414px) {
            u~div .email-container {
                min-width: 414px !important;
            }
        }
    </style>
    <style>
        .primary {
            background: #f3a333;
        }

        .bg_white {
            background: #ffffff;
        }

        .bg_light {
            background: #fafafa;
        }

        .bg_black {
            background: #000000;
        }

        .bg_dark {
            background: rgba(0, 0, 0, .8);
        }

        .email-section {
            padding: 2.5em;
        }

        .btn {
            padding: 10px 15px;
        }

        .btn.btn-primary {
            border-radius: 30px;
            background: #f3a333;
            color: #ffffff;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Playfair Display', serif;
            color: #000000;
            margin-top: 0;
        }

        body {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 1.8;
            color: rgba(0, 0, 0, .4);
        }

        a {
            color: #f3a333;
        }

        table {}

        .logo h1 {
            margin: 0;
        }

        .logo h1 a {
            color: #000;
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
        }

        .hero {
            position: relative;
        }

        .hero img {}

        .hero .text {
            color: rgba(255, 255, 255, .8);
        }

        .hero .text h2 {
            color: #ffffff;
            font-size: 30px;
            margin-bottom: 0;
        }

        .heading-section {}

        .heading-section h2 {
            color: #000000;
            font-size: 28px;
            margin-top: 0;
            line-height: 1.4;
        }

        .heading-section .subheading {
            margin-bottom: 20px !important;
            display: inline-block;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: rgba(0, 0, 0, .4);
            position: relative;
        }

        .heading-section .subheading::after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -10px;
            content: '';
            width: 100%;
            height: 2px;
            background: #f3a333;
            margin: 0 auto;
        }

        .heading-section-white {
            color: rgba(255, 255, 255, .8);
        }

        .heading-section-white h2 {
            font-size: 28px;
            font-family: 1;
            line-height: 1;
            padding-bottom: 0;
        }

        .heading-section-white h2 {
            color: #ffffff;
        }

        .heading-section-white .subheading {
            margin-bottom: 0;
            display: inline-block;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 2px;
            color: rgba(255, 255, 255, .4);
        }

        .icon {
            text-align: center;
        }

        .icon img {}

        .text-services {
            padding: 10px 10px 0;
            text-align: center;
        }

        .text-services h3 {
            font-size: 20px;
        }

        .text-services .meta {
            text-transform: uppercase;
            font-size: 14px;
        }

        /*VIDEO*/

        .img {
            width: 100%;
            height: auto;
            position: relative;
        }

        .img .icon {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            bottom: 0;
            margin-top: -25px;
        }

        .img .icon a {
            display: block;
            width: 60px;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -25px;
        }

        /*FOOTER*/

        .footer {
            color: rgba(255, 255, 255, .5);
        }

        .footer .heading {
            color: #ffffff;
            font-size: 20px;
        }

        .footer ul {
            margin: 0;
            padding: 0;
        }

        .footer ul li {
            list-style: none;
            margin-bottom: 10px;
        }

        .footer ul li a {
            color: rgba(255, 255, 255, 1);
        }

        @media screen and (max-width: 500px) {
            .icon {
                text-align: left;
            }

            .text-services {
                padding-left: 0;
                padding-right: 20px;
                text-align: left;
            }
        }
    </style>


</head>

<body width="100%" style="margin: 0; padding: 0 !important; mso-line-height-rule: exactly; background-color: #222222;">
    <div style="width: 100%; background-color: #f1f1f1;">
        <div
            style="display: none; font-size: 1px;max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden; mso-hide: all; font-family: sans-serif;">
            &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
        </div>
        <div style="max-width: 600px; margin: 0 auto;" class="email-container">
            <!-- BEGIN BODY -->
            <table text-align="center" role="presentation" cellspacing="0" cellpadding="0" text-text-border="0"
                width="100%" style="margin: auto;">
                <tr>
                    <td class="bg_white logo" style="padding: 1em 2.5em; text-align: center">
                        <h1><a href="https://www.pentotest.com">pentotest.com</a></h1>
                    </td>
                </tr>
                <tr>
                    <td class="bg_white">
                        <table role="presentation" cellspacing="0" cellpadding="0" text-text-border="0" width="100%">
                            <tr>
                                <td class="bg_dark email-section" style="text-align:center;">
                                    <div class="heading-section heading-section-white">
                                        <span class="subheading">Welcome</span>
                                        <h2>Welcome To pentotest.com</h2>
                                        <p>Pentotest is providing the best website builder, IT services, the best
                                            Cybersecurity blogs and much more.</p>
                                    </div>
                                </td>
                            </tr>
                            <!-- end: tr -->
                            <tr>
                                <td class="bg_white email-section">
                                    <div class="heading-section" style="text-align: center; padding: 0 30px;">
                                        <span class="subheading">Services</span>
                                        <h2>Our Services</h2>
                                        <p>The best provides services. If you want digitally.</p>
                                    </div>
                                    <table role="presentation" text-text-border="0" cellpadding="0" cellspacing="0"
                                        width="100%">
                                        <tr>
                                            <td valign="top" width="50%" style="padding-top: 20px;">
                                                <table role="presentation" cellspacing="0" cellpadding="0"
                                                    text-text-border="0" width="100%">
                                                    <tr>
                                                        <td class="icon">
                                                            <img src="https://www.pentotest.com/assets/images/blogs.jpg"
                                                                alt="pentotest.com"
                                                                style="width: 60px; max-width: 600px; height: auto; margin: auto; display: block;">
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-services" style="text-align: center;">
                                                            <h3>Website Builder</h3>
                                                            <p>If you want beautiful and responsive website. so <a
                                                                    href="https://www.pentotest.com/contact">click
                                                                    here!</a></p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_light email-section" style="padding: 0; width: 100%;">
                                    <table role="presentation" text-text-border="0" cellpadding="0" cellspacing="0"
                                        width="100%">
                                        <tr>
                                            <td valign="middle" width="50%">
                                                <table role="presentation" cellspacing="0" cellpadding="0"
                                                    text-text-border="0" width="100%">
                                                    <tr>
                                                        <td>
                                                            <img src="http://www.pentotest.com/assets/img/course/imsi-course.gif"
                                                                alt="imsi-cathcer"
                                                                style="width: 100%; max-width: 600px; height: auto; margin: auto; display: block;">
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td valign="middle" width="50%">
                                                <table role="presentation" cellspacing="0" cellpadding="0"
                                                    text-text-border="0" width="100%">
                                                    <tr>
                                                        <td class="text-services"
                                                            style="text-align: left; padding: 20px 30px;">
                                                            <div class="heading-section">
                                                                <span class="subheading">IMSI Catcher Course</span>
                                                                <h2 style="font-size: 22px;">Cellular Network Hacking Course</h2>
                                                                <p>Make sure to hack IMSI Catcher, Understating how to works IMSI Catcher, Under make IMSI Catcher.</p>
                                                                <p><a href="https://www.pentotest.com/course/cellular-network-hacking-course"
                                                                        class="btn btn-primary">Read more</a></p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>

                                <td class="bg_light email-section" style="padding: 0; width: 100%;">
                                    <table role="presentation" text-text-border="0" cellpadding="0" cellspacing="0"
                                        width="100%">
                                        <tr>
                                            <td valign="middle" width="50%">
                                                <table role="presentation" cellspacing="0" cellpadding="0"
                                                    text-text-border="0" width="100%">
                                                    <tr>
                                                        <td class="text-services"
                                                            style="text-align: left; padding: 20px 30px;">
                                                            <div class="heading-section">
                                                                <span class="subheading">Powerful Blogs</span>
                                                                <h2 style="font-size: 22px;">How To Sniffing Calls, SMS,
                                                                    And Mobile Location Using IMSI Catcher</h2>
                                                                <p>How To Sniffing Calls, SMS, And Mobile Location Using
                                                                    IMSI Catcher....</p>
                                                                <p><a href="https://www.pentotest.com/blogs/how-to-sniffing-calls-sms-and-mobile-location-using-imsi-catcher"
                                                                        class="btn btn-primary">Read more</a></p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td valign="middle" width="50%">
                                                <table role="presentation" cellspacing="0" cellpadding="0"
                                                    text-text-border="0" width="100%">
                                                    <tr>
                                                        <td>
                                                            <img src="https://api.pentotest.com/assets/images/blogs/1676888632002.jpg"
                                                                alt="WI-FI"
                                                                style="width: 100%; max-width: 600px; height: auto; margin: auto; display: block;">
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_light email-section" style="padding: 0; width: 100%;">
                                    <table role="presentation" text-text-border="0" cellpadding="0" cellspacing="0"
                                        width="100%">
                                        <tr>
                                            <td valign="middle" width="50%">
                                                <table role="presentation" cellspacing="0" cellpadding="0"
                                                    text-text-border="0" width="100%">
                                                    <tr>
                                                        <td>
                                                            <img src="https://api.pentotest.com/assets/images/blogs/1677328521136.jpg"
                                                                alt="SSH"
                                                                style="width: 100%; max-width: 600px; height: auto; margin: auto; display: block;">
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td valign="middle" width="50%">
                                                <table role="presentation" cellspacing="0" cellpadding="0"
                                                    text-text-border="0" width="100%">
                                                    <tr>
                                                        <td class="text-services"
                                                            style="text-align: left; padding: 20px 30px;">
                                                            <div class="heading-section">
                                                                <span class="subheading">Network</span>
                                                                <h2 style="font-size: 22px;">How To Downgrade 4G, LTE,
                                                                    Or 3G Network To 2G GSM Network</h2>
                                                                <p>How To Downgrade 4G, LTE, Or 3G Network To 2G GSM
                                                                    Network.</p>
                                                                <p><a href="https://www.pentotest.com/blogs/how-to-downgrade-4g-lte-or-3g-network-to-2g-gsm-network"
                                                                        class="btn btn-primary">Read more</a></p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                        </table>
                    </td>
                </tr>
                <!-- end: tr -->
                <tr>
                    <td valign="middle" class="bg_black footer email-section">
                        <table>
                            <tr>
                                <td valign="top" width="33.333%">
                                    <table role="presentation" cellspacing="0" cellpadding="0" text-border="0"
                                        width="100%">
                                        <tr>
                                            <td style="text-align: left; padding-right: 10px;">
                                                <p>Copyright www.pentotest.com © ${new Date().getFullYear()}, All rights
                                                    reserved</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td valign="top" width="33.333%">
                                    <table role="presentation" cellspacing="0" cellpadding="0" text-border="0"
                                        width="100%">
                                        <tr>
                                            <td style="text-align: right; padding-left: 5px; padding-right: 5px;">
                                                <p><a href="https://www.pentotest.com/contact">Unsubscribe</a></p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</body>

</html>`

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />
                <Profile />
                {/* PageTitle */}

                {/*blog-grid-area start*/}
                <section className="pt-150 pb-105 pt-lg-60 pb-lg-15">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="contact-form-two pl-3 pr-3">

                                    <div className="post-title mb-50 text-center mt-0 mb-4">
                                        <h4 className="text-white">Send a Mail</h4>
                                    </div>
                                    <form className="widget-form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                        <div className="row gx-4 gx-xxl-5 px-0">
                                            <div className="col-lg-12 col-md-12 col-sm-12 mb-10">
                                                <div className="form-input-box">
                                                    <label className="fs-16 text-white">Choose Your Subject: </label>
                                                    <input type="text" placeholder="Subject*"
                                                        {...register('subject', { required: true, maxLength: 50 })}
                                                    />
                                                    {errors.subject &&
                                                        errors.subject.type === "required" &&
                                                        <div className="text-danger">Please enter subject.</div>
                                                    }
                                                    {errors.subject &&
                                                        errors.subject.type === "maxLength" &&
                                                        <div className="text-danger">Please enter valid subject.</div>}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 mb-10">
                                                <Editor onInit={(evt, editor) => editorRef.current = editor}
                                                    apiKey='8cfe2vu4ntju24d0u6uxwkp7qwnxhs6zg7jymabp4vbrhnxt'
                                                    initialValue={mailcontent}
                                                    init={{
                                                        height: 500,
                                                        menubar: true,
                                                        browser_spellcheck: true,
                                                        plugins: [
                                                            'advlist autolink lists link image code charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar: 'undo redo | formatselect | image code |' +
                                                            'bold italic backcolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help | code',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                        file_picker_types: 'image',
                                                        image_file_types: 'jpg,svg,webp,gif',
                                                        paste_data_images: true,

                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4">
                                                <button type="submit" className="btn btn-primary mt-40">Send</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/*blog-grid-area end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default SendMail