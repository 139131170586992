import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import axios from 'axios'
import AWN from 'awesome-notifications'
import Spinner from '../../components/Spinner'

const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    loop: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 15,
    center: false,
    centerPadding: 0,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 820,
            settings: {
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 767.98,
            settings: {
                arrows: false,
                slidesToShow: 1,
            }
        }
    ]
};

const BlogsSlider = () => {

    const [BlogContent, setUsers] = useState([]);
    const [Loshow, setLoShow] = useState(false);
    const handleLoClose = () => setLoShow(false);
    const handleLoShow = () => setLoShow(true);

    useEffect(() => {
        handleLoShow();
        axios.post(`https://api.pentotest.com/fetchhomeblogsSix`)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                    handleLoClose();
                } else {
                    handleLoClose();
                    setUsers(res.data.data);
                }
            }).catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <Fragment>
            <div className={Loshow ? `divOne` : 'divOne d-none'}>
                <div className="text-center">
                    <Spinner />
                </div>
            </div>
            <Slider className="row gx-0 blog-active-one" data-aos="fade-up" data-aos-delay={100} {...settings}>
                {BlogContent.map((data, i) => (
                    <div key={i} className="col-lg-4">
                        <div className="blog-style-one">
                            <div className="blog-thumb">
                                <img className="w-100 image-size" src={data.file} alt={data.title} />
                                <a href={() => false} className="tag">{data.category}</a>
                            </div>
                            <div className="blog-content">
                                <h4>
                                    <Link className="blog-title" to={`/blogs/${data.slug}`} >{data.title}</Link>
                                </h4>
                                <div className="blog-meta">
                                    <a href={() => false}>
                                        <i className="bi bi-person-plus" />
                                        {data.author}
                                    </a>
                                    <a href={() => false}>
                                        <i className="bi bi-clock" />
                                        {data.created_at}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </Fragment>
    )
}

export default BlogsSlider