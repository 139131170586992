import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import AWN from 'awesome-notifications'

export const SubscribeFormOne = () => {

    const { register, handleSubmit, formState: { errors }, } = useForm();
    const onSubmit = data => {
        axios.post(
            `https://api.pentotest.com/subscriber`, data)
            .then(res => {
                if (res.data.status === 1) {
                    new AWN().success(`${res.data.message}`);
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });
    }

    return (
        <Fragment>
            <form className="sub-form" onSubmit={handleSubmit(onSubmit)}>
                <input type="email" placeholder="Email Address*" {...register('email', { required: true, maxLength: 30, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                <button title="submit" type="submit"><i className="bi bi-arrow-right" /></button>
                {errors.email &&
                    errors.email.type === "required" &&
                    <div className="text-danger">Please enter email address.</div>}
                {errors.email &&
                    errors.email.type === "maxLength" &&
                    <div className="text-danger">Please enter valid email address.</div>}
                {errors.email &&
                    errors.email.type === "pattern" &&
                    <div className="text-danger">Please enter valid email address.</div>}
            </form>
        </Fragment>
    )
}
