import React, { Fragment } from 'react'

const Spinner = () => {
    return (
        <Fragment>
            <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </Fragment>
    )
}

export default Spinner