import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'

//components
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import ModalVideos from '../../../components/modal-video/ModalVideos'
import ToolsList from '../../../components/Service/ToolsList'
import Skill from '../../../components/skill/Skill'
import CommonCounter from '../../../components/counter/CommonCounter'
import Newsletter from '../../../components/call-to-action/Newsletter'
import Footer from '../../../components/footer/Footer'


const ServicesDetails = () => {
    const [open, setOpen] = useState(false);
    const OpenModal = () => {
        setOpen(!open)
    }
    return (
        <Fragment>
            <ModalVideos isOpen={open} onClick={OpenModal} />
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Service-Details | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />

                {/* PageTitle */}
                <PageTitle title="Service Details" />
                {/* PageTitle */}

                {/*our-services start*/}
                <section className="service-details-wrapper pt-145 pb-100 pt-lg-60 pb-lg-20">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12">
                                <div className="widget-left-section">
                                    <p className="text-white mt-15 mb-45">As a cyber security professional, you provide expertise in protecting digital assets from unauthorized access, use, or destruction. This includes conducting vulnerability assessments, implementing security measures, and responding to cyber attacks or incidents. Your technology blogs are likely focused on providing information and insights on the latest trends and best practices in the technology industry, with a particular focus on cyber security topics.</p>
                                    <p className="text-white mt-15 mb-45">As an IT service provider, you offer a range of technology-related services to businesses and individuals. These may include computer network setup and maintenance, software installation and support, and hardware troubleshooting and repair.</p>
                                    <p className="text-white mt-15 mb-45">As a website builder, you design and create websites for businesses and individuals. This includes developing the website's layout, selecting the appropriate technologies and programming languages, and ensuring the website is optimized for both usability and search engine rankings.</p>
                                    <p className="text-white mt-15 mb-45">In summary, your services encompass a wide range of technology-related offerings, from providing cyber security expertise to designing and building websites, and offering IT services to help businesses and individuals make the most of their technology.</p>
                                    <h3 className="sect-title-two fw-bold mb-20">Our Specialized</h3>
                                    {/* Skill start */}
                                    <Skill />
                                    {/* Skill end */}
                                    <h3 className="sect-title-two fw-bold mb-20">Our Skills</h3>
                                    <p className="text-white">We present you with the latest application of business and expertise to your organization, helping you manage and optimize business processes.</p>
                                    <p className="text-white">As an IT service provider, you offer a range of technical solutions to help businesses meet their technological needs. Your services may include developing and implementing software applications, managing hardware and network infrastructure, providing technical support and consulting, and ensuring data security and backup.</p>
                                    <p className="text-white">To effectively explain your IT services in text, you can start by introducing your company and the services you offer. You can highlight your expertise in various areas such as software development, network administration, cybersecurity, and cloud computing.</p>
                                    <p className="text-white">You can also emphasize the benefits of your services, such as improving operational efficiency, increasing productivity, reducing downtime, enhancing data security, and enabling digital transformation.</p>
                                    {/* ToolsList */}
                                    <ToolsList />
                                    {/* ToolsList */}
                                    <h3 className="sect-title-two fw-bold mb-20">We Provide Services that You can Rely on</h3>
                                    <div className="service-video d-md-flex mt-40">
                                        <div className="service-single mb-40">
                                            <img className="w-100" src="/assets/img/blogs.jpg" alt="services-imgs" />
                                        </div>
                                        <div className="service-single mb-40">
                                            <img className="w-100" src="/assets/img/it-services.jpg" alt="services-imgs" />
                                        </div>
                                        {/* <div className="service-single-video mb-40">
                                            <img className="w-100" src="assets/img/service/img-03.jpg" alt="services-imgs" />
                                            <a href={() => false} className="popup-video mb-30" onClick={OpenModal} ><i className="bi bi-play-fill" /></a>
                                        </div> */}
                                        <div className="service-single mb-40">
                                            <img className="w-100" src="/assets/img/website.jpg" alt="services-imgs" />
                                        </div>
                                    </div>
                                    <ul className="text-list list-none">
                                        <li>We have the expertise to secure your website and application.
                                        </li>
                                        <li>As a professional I build a website and application.
                                        </li>
                                        <li>The best mobile technology for your
                                        </li>
                                    </ul>
                                    <div className="row mt-20 text-center">
                                        <div className="col-md-3" data-aos="fade-up">
                                            <div className="counter-style-two boder-style mb-45">
                                                <h3><span className="counter"><CommonCounter value="56" /></span>+</h3>
                                                <p className="text-white mt-20">We Develop Over 56+ Software</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3" data-aos="fade-up">
                                            <div className="counter-style-two boder-style mb-45">
                                                <h3><span className="counter"><CommonCounter value="25" /></span>+</h3>
                                                <p className="text-white mt-20">Global Clients & Trusted Us</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3" data-aos="fade-up">
                                            <div className="counter-style-two boder-style mb-45">
                                                <h3><span className="counter"><CommonCounter value="12" /></span>+</h3>
                                                <p className="text-white mt-20">Local & Global
                                                    VAPT</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3" data-aos="fade-up">
                                            <div className="counter-style-two boder-style mb-45">
                                                <h3><span className="counter"><CommonCounter value="36" /></span>+</h3>
                                                <p className="text-white mt-20">Clients Reviews Our Software</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*our-services end*/}

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default ServicesDetails