import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'



const BlockStyleSeven = () => {
    return (
        <Fragment>
            <div className="block-style-seven pl-90 mb-45 pl-md-0">
                <h6 className="sub-title-two mb-20">About Us</h6>
                <h2 className="sect-title mb-20">Experience in Cyber Security.</h2>
                <p>Welcome to <b>PENTOTEST.COM</b>, your go-to source for the latest insights in Cyber Security and technology. Our mission is to empower individuals and businesses with essential knowledge and tools.
                    We are a team of Cyber Security experts and technology enthusiasts, committed to providing high-quality, informative content. Founded in 2019, we began our journey as a small blog, evolving into a comprehensive resource for professionals and hobbyists alike.
                    We offer in-depth articles, practical tutorials, and the latest news. Integrity, innovation, and reliability guide us.</p>
                <div className="icon-box">
                    <span><img src="assets/img/icon/icon-28c.svg" alt="icon" /></span>
                    <span><img src="assets/img/icon/icon-29c.svg" alt="icon" /></span>
                    <span><img src="assets/img/icon/icon-30c.svg" alt="icon" /></span>
                    <span><img src="assets/img/icon/icon-31c.svg" alt="icon" /></span>
                    <span><img src="assets/img/icon/icon-32c.svg" alt="icon" /></span>
                </div>
                <Link className="theme_btn mt-55" to="/about">More Details</Link>
            </div>
        </Fragment>
    )
}

export default BlockStyleSeven