import React,  { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default function AdminProfile() {

    return (
        <Fragment>
            <div className="blog-single-area pt-50 pb-0 pt-lg-60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="recent_post_dtls pl-3 pr-3">
                                <div className="row">
                                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <div className="form-hid">
                                            <label>
                                                <div className="profile-picture">
                                                    <div id="uploaded_image" className="profile-pic"
                                                        style={{ backgroundImage: localStorage.getItem("user_image") === 'default_image.png' ? 'url(' + process.env.PUBLIC_URL + '/assets/img/default_image.png)' : 'url(' + localStorage.getItem("user_image") + ')' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" className="bi bi-camera"
                                                            viewBox="0 0 16 16">
                                                            <path
                                                                d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                                                            <path
                                                                d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                                                        </svg>
                                                        <span>Update Profile</span>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <div className="pl-0">
                                            <h5 className="text-capitalize text-white">
                                                {localStorage.getItem('name')}
                                            </h5>
                                            <p className="mt-1 mb-2 text-white">
                                                {localStorage.getItem("description")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-3 pb-3">
                                    <div className="hr"></div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                        <Link className="ms-1" to="/admin"><button type="button" className="btn btn-light">Subscriber</button></Link>
                                        <Link className="ms-1" to="/admin/allusers"><button type="button" className="btn btn-light">All Users</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
