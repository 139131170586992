import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Tilt from 'react-parallax-tilt'

//components
import Header from '../../components/header/Header'
import ThemeBanner from '../../components/theme-banner/ThemeBanner'
import { } from '../../components/brand/BrandOne'
import BlockStyle from '../../components/block/BlockStyle'
import CaseProductSlider from '../../components/case-study/CaseProductSlider'
import Team from '../../components/team/Team'
import Feature from '../../components/feature/Feature'
import CounterOne from '../../components/counter/CounterOne'
import Feedback from '../../components/testimonial/Feedback'
import BlogsSlider from '../../components/blog/BlogsSlider'
import Newsletter from '../../components/call-to-action/Newsletter'
import Footer from '../../components/footer/Footer'

const Home = () => {
  return (
    <Fragment>

      <div className="main-page-wrapper">
        {/* Helmet start */}
        <Helmet>
          <title>Welcome To The Pentotest.com | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
        </Helmet>
        <Header />
        <ThemeBanner />
        <section className="about-techy bg-shape-3c pt-40 pb-105 pt-lg-60 pb-lg-10">
          <img
            className="about-shape-3c shape-1b d-none d-xl-inline-block"
            src={process.env.PUBLIC_URL + "/assets/img/shape/star-3b.svg"}
            alt="shape" />
          <img
            className="about-shape-3c shape-2b d-none d-md-inline-block"
            src={process.env.PUBLIC_URL + "/assets/img/shape/star-4b.svg"}
            alt="shape" />
          <img
            className="about-shape-3c shape-3b d-none d-lg-inline-block"
            src={process.env.PUBLIC_URL + "/assets/img/shape/star-4b.svg"}
            alt="shape" />
          <img
            className="about-shape-3c shape-4b"
            src={process.env.PUBLIC_URL + "/assets/img/shape/star-7c.svg"}
            alt="shape" />
          <img
            className="about-shape-3c shape-5b d-none d-xl-inline-block"
            src={process.env.PUBLIC_URL + "/assets/img/shape/star-4b.svg"}
            alt="shape" />
          <div className="container">
            <div className="row gx-4 gx-xxl-5 align-items-center">
              <div className="col-lg-6" data-aos="fade-right" data-aos-delay={100}>
                <div
                  data-tilt
                  className="about-img-wrapper-three text-lg-end position-relative mb-45">
                  <Tilt>
                    <img className="main-img" src={process.env.PUBLIC_URL + "/assets/img/about/ilus-1c.svg"} alt="ilustrate" />
                  </Tilt>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-left" data-aos-delay={100}>
                <BlockStyle />
              </div>
            </div>
          </div>
        </section>
        {/*about-techy end*/}

        {/*techy-team start*/}
        <section className="gradient-bg techy-team pt-210 pt-lg-180 pt-sm-60 pb-100 pb-lg-55">
          <img className="team-shape shape-1b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-3b.svg"} alt="shape" />
          <img className="team-shape shape-2b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-4b.svg"} alt="shape" />
          <img className="team-shape shape-3b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-2.svg"} alt="shape" />
          <img className="team-shape shape-4b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-5b.svg"} alt="shape" />
          <img className="team-shape shape-5b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-5b.svg"} alt="shape" />
          <img className="team-shape shape-6b" src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-6b.svg"} alt="shape" />
          <div className="container">
            <div className="row gx-4 gx-xxl-5">
              <div className="col-lg-12">
                <div className="section-title text-center mb-55">
                  <h6 className="sub-title mb-20">All Course</h6>
                  <h2 className="sect-title">See Our Latest Courses</h2>
                </div>
              </div>
            </div>
            {/* TeamOne */}
            <CaseProductSlider />
            {/* TeamOne */}
            <div className="row gx-4 gx-xxl-5">
              <div className="col-lg-12 text-center pt-15" data-aos="fade-up" data-aos-delay={200}>
                <Link className="theme_btn" to="/courses">All Course</Link>
              </div>
            </div>
          </div>
        </section>
        {/*techy-team end*/}

        {/*techy-team start*/}
        <section className="gradient-bg techy-team pt-210 pt-lg-180 pt-sm-60 pb-100 pb-lg-55">
          <img className="team-shape shape-1b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-3b.svg"} alt="shape" />
          <img className="team-shape shape-2b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-4b.svg"} alt="shape" />
          <img className="team-shape shape-3b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-2.svg"} alt="shape" />
          <img className="team-shape shape-4b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-5b.svg"} alt="shape" />
          <img className="team-shape shape-5b" src={process.env.PUBLIC_URL + "/assets/img/shape/star-5b.svg"} alt="shape" />
          <img className="team-shape shape-6b" src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-6b.svg"} alt="shape" />
          <div className="container">
            <div className="row gx-4 gx-xxl-5">
              <div className="col-lg-12">
                <div className="section-title text-center mb-55">
                  <h6 className="sub-title mb-20">Our Team</h6>
                  <h2 className="sect-title">Expert Team Member</h2>
                </div>
              </div>
            </div>
            {/* TeamOne */}
            <Team />
            {/* TeamOne */}
            <div className="row gx-4 gx-xxl-5">
              <div className="col-lg-12 text-center pt-15" data-aos="fade-up" data-aos-delay={200}>
                <Link className="theme_btn" to="/team">See All Member</Link>
              </div>
            </div>
          </div>
        </section>
        {/*techy-team end*/}

        {/*our-services start*/}
        <section className="gradient-bg our-services service-bg-three pt-95 pb-55 pt-lg-55 pb-lg-15">
          <img className="service-shape-3c" src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-6b.svg"} alt="shape" />
          <div className="container">
            <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="section-title text-center mb-55">
                  <h3 className="sect-title mb-25" data-aos="fade-up">Our Services</h3>
                  <p data-aos="fade-up" data-aos-delay={100}>Dramatically supply transparent
                    backward deliverables before caward comp internal or "organic" sources.</p>
                </div>
              </div>
            </div>
            {/* FeatureFive */}
            <Feature />
            {/* FeatureFive */}
          </div>
        </section>
        {/*our-services end*/}

        {/*counter-one end*/}
        <CounterOne />
        {/*counter-one start*/}

        {/*client-feedback start*/}
        <section className="client-feedback pt-140 pb-150 pt-lg-55 pb-lg-60">
          <div className="container">
            <div className="row gx-4 gx-xxl-5 justify-content-center" data-aos="fade-up">
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="section-title text-center mb-55">
                  <h3 className="sect-title mb-25">Feedback</h3>
                  <p>Our Happy Clients Says</p>
                </div>
              </div>
            </div>
            {/* FeedbackOne start */}
            <Feedback />
            {/* FeedbackOne start */}
          </div>
        </section>
        {/*client-feedback end*/}

        {/*blog-area start*/}
        <section className="blog-area slick-nav blog-slick pt-100 pb-100 pt-lg-55 pb-lg-60">
          <div className="container">
            <div className="row gx-4 gx-xxl-5 align-items-center">
              <div className="col-xl-5 col-lg-6 col-md-8" data-aos="fade-up">
                <div className="section-title text-center text-md-start mb-60">
                  <h3 className="sect-title mb-25">Latest Article</h3>
                </div>
              </div>
            </div>
            {/*blog-style-one end*/}
            <BlogsSlider />
            {/*blog-style-one end*/}

          </div>
        </section>
        {/*blog-area end*/}

        {/*techy-newsletter start*/}
        <Newsletter />

        {/*techy-newsletter end*/}

        {/*footer-area start*/}
        <Footer />
        {/*footer-area end*/}

      </div>
    </Fragment>
  )
}

export default Home