import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AWN from 'awesome-notifications'

const SideBar = (props) => {

    const { onClick, value } = props
    const navigate = useNavigate()

    const logOut = () => {
        localStorage.clear()
        new AWN().success('Logged Out Successfully.')
        navigate.push('/')
    };

    return (
        <Fragment>

            {/* slide-bar start */}
            <aside className={value ? "slide-bar show" : "slide-bar"}>
                <div className="close-mobile-menu">
                    <a href={() => false} onClick={onClick}>
                        <i className="fas fa-times" />
                    </a>
                </div>
                {/* offset-sidebar start */}
                <div className="offset-sidebar">
                    <div className="offset-widget offset-logo mb-30">
                        <Link to="/">
                            <img src={process.env.PUBLIC_URL + "/assets/img/logo/header-logo-1.png"} alt="logo" />
                        </Link>
                    </div>
                    <div className="offset-widget mb-40">
                        <div className="info-widget">
                            <h4 className="offset-title mb-20">About US</h4>
                            <p className="mb-30">
                                I am here to give information about cyber security and technology.
                            </p>
                        </div>
                    </div>
                    <div className="offset-widget mb-30 pr-10">
                        <div className="info-widget info-widget2">
                            <h4 className="offset-title mb-20">Contact Info</h4>
                            <p>
                                <i className="fal fa-address-book" />
                                Basara, Basara Barabanki India 225305.</p>
                            <p>
                                <i className="fal fa-phone" />
                                +91 99909 34025
                            </p>
                            <p>
                                <i className="fal fa-envelope-open" />
                                support@pentotest.com
                            </p>
                        </div>
                    </div>
                    <div className="login-btn text-center">
                        {localStorage.getItem('role') === 'admin' ?
                            <>
                             <Link className="theme_btn w-100" to="/admin">Admin</Link>
                             <Link className="theme_btn w-100" onClick={logOut}>LogOut</Link>
                            </> :
                            localStorage.getItem('role') === 'public' ?
                                <>
                                 <Link className="theme_btn w-100" to="/account">Account</Link>
                                 <Link className="theme_btn w-100" onClick={logOut}>Logout</Link>
                                </> :
                                <>
                                 <Link className="theme_btn w-100" to="/login">Login</Link>
                                 <p className="text-white">Don't have an account ? Please <Link to="/signup" className="border-bottom">Signup</Link> today</p>
                                </>}
                    </div>
                </div>
                {/* offset-sidebar end */}
            </aside>
            {/* slide-bar end */}
        </Fragment>
    )
}

export default SideBar