import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MetisMenu from '@metismenu/react'
import 'metismenujs/dist/metismenujs.css'
import AWN from 'awesome-notifications'

const Company = [
  {
    name: 'About Us',
    routerPath: '/about'
  },
  {
    name: 'Team',
    routerPath: '/team'
  },
  {
    name: 'Team Details',
    routerPath: '/team-details'
  },

];

const Services = [
  {
    name: 'Services',
    routerPath: '/services'
  },
  {
    name: 'Service Details',
    routerPath: '/service-details'
  },
  {
    name: 'Contact',
    routerPath: '/contact'
  },
];

const Blogs = [
  {
    name: 'All Category Blog',
    routerPath: '/blogs'
  },
];

const Courses = [
  {
    name: 'Courses',
    routerPath: '/courses'
  },
];


const MobileMenu = (props) => {

  const { onClick, active } = props
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear()
    new AWN().success('Logged Out Successfully.')
    navigate.push('/')
  }


  return (
    // <div className={active?"mobile-menu-wrapper show d-lg-none":"mobile-menu-wrapper d-lg-none"}>
    <div className={`mobile-menu-wrapper ${active} d-lg-none`}>
      <div className="mobile-logo mb-5">
        <Link to="/"><img src="assets/img/logo/header-logo-1.png" alt="" /></Link>
      </div>
      <div className="close-menu" onClick={onClick}>
        <i className="bi bi-x-lg"></i>
      </div>
      <div className="side-mobile-menu">
        <MetisMenu>
          <li onClick={onClick}>
            <Link to="/">Home</Link>
          </li>
          <li className="has-dropdown">
            <a href={() => false}>Company</a>
            <ul className="sub-menu">
              {Company.map((val, i) => (
                <li onClick={onClick} key={i}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </li>
              ))}
            </ul>
          </li>
          <li className="has-dropdown">
            <a href={() => false}>Services</a>
            <ul className="sub-menu">
              {Services.map((val, i) => (
                <li onClick={onClick} key={i}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </li>
              ))}
            </ul>
          </li>
          <li className="has-dropdown">
            <a href={() => false}>Blogs</a>
            <ul className="sub-menu">
              {Blogs.map((val, i) => (
                <li onClick={onClick} key={i}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </li>
              ))}
            </ul>
          </li>
          <li className="has-dropdown">
            <a href={() => false}>Courses</a>
            <ul className="sub-menu">
              {Courses.map((val, i) => (
                <li onClick={onClick} key={i}>
                  <Link to={val.routerPath}>{val.name}</Link>
                </li>
              ))}
            </ul>
          </li>
          <li onClick={onClick}>
            <Link to="/contact">Contact Us</Link>
          </li>
        </MetisMenu>
      </div>
      <div className="login-btn text-center mt-4">
        {localStorage.getItem('role') === 'admin' ?
          <>
            <Link className="theme_btn w-100" to="/admin">Admin</Link>
            <Link className="theme_btn w-100" onClick={logOut}>LogOut</Link>
          </> :
          localStorage.getItem('role') === 'public' ?
            <>
              <Link className="theme_btn w-100" to="/account">Account</Link>
              <Link className="theme_btn w-100" onClick={logOut}>Logout</Link>
            </> :
            <>
              <Link className="theme_btn w-100" to="/login">Login</Link>
              <p className="text-white">Don't have an account ? Please <Link to="/signup" className="border-bottom">Signup</Link> today</p>
            </>}
      </div>
    </div>
  )
}

export default MobileMenu