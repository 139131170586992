import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

//componets
import Header from '../../components/header/Header'
import Newsletter from '../../components/call-to-action/Newsletter'
import Footer from '../../components/footer/Footer'
import Profile from './Profile'
import FeedbackForm from '../../components/form/FeedbackForm'


const Feedback = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />
                <Profile />
                {/* PageTitle */}

                {/*blog-grid-area start*/}
                <section
                    className="gradient-bg our-services service-bg-three pt-95 pb-55 pt-lg-55 pb-lg-15">
                    <img
                        className="service-shape-3c"
                        src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-6b.svg"}
                        alt="shape" />
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title text-center mb-55">
                                    <h3 className="sect-title mb-25" data-aos="fade-up">Feedback for Website</h3>
                                    <p data-aos="fade-up" data-aos-delay={100}>We would like your feedback to improve our website. What is your opinion of this page.</p>
                                </div>
                            </div>
                        </div>
                        {/* FeatureFive */}
                        <FeedbackForm />
                        {/* FeatureFive */}
                    </div>
                </section>
                {/*blog-grid-area end*/}

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Feedback