import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'

const featuresContent =[
    {
        img:'line-round-5b',
        icon:'icon-21b',
        title:'Vulnerability Assessment',
        desc:'Automated scanning for known vulnerabilities and Manual verification of findings.',
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '200',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-22b',
        title:'Penetration Testing',
        desc:'External and internal network penetration testing, Web application penetration testing and Exploitation of identified vulnerabilities to assess potential impact',
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '250',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-19b',
        title:'Web Security',
        desc:'Application security testing and Secure authentication and authorization mechanisms.',
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '100',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-20b',
        title:'Data Protection',
        desc:'Data encryption in transit and at rest. and Regular data backups and disaster recovery planning',
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '150',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-23b',
        title:'Online IT Service',
        desc:'We have the expertise to secure your website and application.',
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '250',
        dataDelayDown: '100',
    },
    {
        img:'line-round-5b',
        icon:'icon-18b',
        title:'Cyber Security',
        desc:'Most popular and powerful blog based on cyber security and technology.',
        dataAos:'fade-up',
        dataAosDown:'fade-down',
        dataDelay: '',
        dataDelayDown: '100',
    }
]

const Feature = () => {
    return (
        <Fragment>
            <div className="row gx-4 gx-xxl-5">
                {featuresContent.map((val,i)=>(
                    <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                    <div className="feature-style-four mb-45">
                        <img
                            className="shape-over"
                            data-aos={val.dataAosDown}
                            data-aos-delay={val.dataDelayDown}
                            src={`assets/img/shape/${val.img}.svg`}
                            alt="shape"/>
                        <img src={`assets/img/icon/${val.icon}.svg`} alt="icon"/>
                        <h2>
                            <Link className="sect-title-two" to="/services-details">{val.title}</Link>
                        </h2>
                        <p>{val.desc}</p>
                    </div>
                </div>
                ))}
            </div>
        </Fragment>
    )
}

export default Feature