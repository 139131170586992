import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import AWN from 'awesome-notifications'

import Spinner from '../../components/Spinner'


const AllBlogsSlider = () => {

    const [BlogContent, setUsers] = useState([]);
    const [totalPage, setTotalPage] = useState([]);

    const [Lodershow, setLoShow] = useState(false);
    const handleLoClose = () => setLoShow(false);
    const handleLoShow = () => setLoShow(true);

    const items = [];
    for (let i = 0; i < totalPage; i++) {
        items.push(
            <li key={i} className="page-item">
                <a href={() => false} className="page-link" onClick={event => pagina({i})}>{i+1}</a>
            </li>
        );
    }

    const pagina = (pageNum) => {
        handleLoShow()
        axios.post(`https://api.pentotest.com/fetchblogs/${pageNum.i+1}`)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`)
                } else {
                    setUsers(res.data.data)
                    handleLoClose()
                }
            }).catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        handleLoShow();
        axios.post(`https://api.pentotest.com/fetchblogs/1`)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`)
                    handleLoClose()
                } else {
                    setUsers(res.data.data)
                    setTotalPage(res.data.totalPage)
                    handleLoClose();
                }
            }).catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <Fragment>
            <div className={Lodershow ? `divOne` : 'divOne d-none'}>
                <div className="text-center">
                    <Spinner />
                </div>
            </div>
            {BlogContent.map((data, i) => (
                <div key={i} className="blog-style-two style-four mb-lg-45 mb-275">
                    <div className="blog-thumb">
                        <img className="w-100 image-size" src={data.file} alt={data.title} />
                    </div>
                    <div className="blog-content">
                        <div className="blog-tag pb-10">
                            {data.category}
                        </div>
                        <h3>
                            <Link className="blog-title" to={`/blogs/${data.slug}`}>{data.title}</Link>
                        </h3>
                        <div className="blog-meta">
                            <a href={() => false} className="mr-20"><i className="bi bi-person-plus" />
                                {data.author}</a>
                            <a href={() => false}><i className="bi bi-clock" />{data.created_at}</a>
                        </div>
                        <Link className="more-btn" to={`/blogs/${data.slug}`}>Read More</Link>
                    </div>
                </div>
            ))}
            <div className="page-navigation">
                <ul className="pagination">
                    {items}
                </ul>
            </div>
        </Fragment>
    )
}

export default AllBlogsSlider