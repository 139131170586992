import React, { Fragment, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import axios from "axios"
import AWN from 'awesome-notifications'

//componets
import Header from '../../components/header/Header'
import PageTitle from '../../components/page-title/PageTitle'
import ModalVideos from '../../components/modal-video/ModalVideos'
import Newsletter from '../../components/call-to-action/Newsletter'
import Footer from '../../components/footer/Footer'
import CourseVideos from '../../components/account/CourseVideos'

const ViewProduct = () => {
    const [open, setOpen] = useState(false);
    const OpenModal = () => {
        setOpen(!open)
    }

    const [dataf, setUsers] = useState();

    useEffect(() => {

        const data1 = { user_id: localStorage.getItem("user_id") };
        axios.post(`https://api.pentotest.com/account`, data1)
            .then(res => {
                if (res.data.status === 1) {
                    setUsers(res.data.data[0].product_status);
                    // handleLoClose();
                } else {
                    new AWN().alert(`${res.data.message}`);
                    //  handleLoClose();
                }
            }).catch(error => {
                console.log(error);
            });

    }, []);

    return (
        <Fragment>
            <ModalVideos isOpen={open} onClick={OpenModal} />
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <Header />
                {/* ThemeMenuThree */}

                {/* PageTitle */}
                <PageTitle title="Cellular Network Hacking Course" />
                {/* PageTitle */}

                {/*blog-single-area start*/}
                <section className="blog-single-area pt-0 pb-0 pt-lg-0">
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12 mb-60 mt-60">
                                {!dataf ?
                                    <>
                                        <h4 className="text-danger text-center">No purchased products found.</h4>
                                    </>
                                    :
                                    <>
                                        <CourseVideos />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {/*blog-single-area end*/}


                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default ViewProduct