import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

//componets
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Profile from './Profile'
import ProductSlider from '../../components/case-study/ProductSlider';


const MyProducts = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />
                <Profile />
                {/* PageTitle */}

                {/*blog-grid-area start*/}
                <section className="pt-150 pb-105 pt-lg-60 pb-lg-15">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="contact-form-two pl-3 pr-3">

                                    <div className="post-title mb-50 text-center mt-0 mb-4">
                                        <h4 className="text-white">My Course</h4>
                                    </div>
                                    <ProductSlider />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/*blog-grid-area end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default MyProducts