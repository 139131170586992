import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import AWN from 'awesome-notifications'
import { Helmet } from 'react-helmet'
import { Button, Modal } from 'react-bootstrap'
import ReCaptcha from '@matt-block/react-recaptcha-v2'
import { useForm } from 'react-hook-form'

//componets
import Header from '../../components/header/Header'
import Newsletter from '../../components/call-to-action/Newsletter'
import Footer from '../../components/footer/Footer'
import Profile from './Profile'


const Feedback = () => {

    const [modalIsopen, setShow] = useState(false);
    const closeModal = () => setShow(false);
    const handleShow = () => setShow(true);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [disable, setDisable] = useState(true);
    const [gatCaptcha, setCaptcha] = useState();

    const onSubmit = (data, e) => {

        const payload = { user_id: localStorage.getItem("user_id"), captcha: gatCaptcha, token: localStorage.getItem("token") };

        axios.post(
            `https://api.pentotest.com/deleteAccount`, payload)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else if (res.data.status === 3) {
                    new AWN().alert(`${res.data.message}`);
                    window.location.reload();
                } else if (res.data.status === 1) {
                    new AWN().success(`${res.data.message}`);
                    localStorage.clear();
                    window.location.href = '/';
                } else {
                    new AWN().alert(`${res.data.message}`);
                }
            }).catch(error => {
                console.log(error);
            });

    }

    const delayModal = () => {
        handleShow()
    }

    function onChange(value) {
        if (value !== null) {
            setCaptcha(value);
            setDisable(false);
        } else {
            setDisable(true);
        }
    }

    return (
        <Fragment>
            <Modal show={modalIsopen} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title><b className="text-danger">Delete Account</b></Modal.Title>
                    <Button variant="link" onClick={closeModal}><i className="fa fa-times text-dark" aria-hidden="true"></i></Button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="text-dark">
                            <div className="col-lg-12 mt-10">
                                <p className="text-dark">Are you sure you want to delete your account?</p>
                                <input type="checkbox" className="form-check-input mt-2"
                                    {...register('checkbox', { required: true })}
                                />
                                <label className="form-check-label text-dark pl-5">I agree to the <Link className="text-primary"
                                    to="/terms-and-conditions">terms &amp; conditions</Link></label>
                                {errors.checkbox &&
                                    errors.checkbox.type === "required" &&
                                    <div className="text-danger">You must agree before submitting.</div>}
                            </div>
                            <div className="col-lg-12 mt-30">
                                <ReCaptcha
                                    siteKey="6Lcd74MkAAAAAFzbtAGPRmgoP-T9cH6YaXAsaP_q"
                                    theme="light"
                                    size="normal"
                                    onSuccess={(captcha) => onChange(captcha)}
                                    onExpire={() => onChange(null)}
                                    onError={() => onChange(null)}
                                />
                            </div>
                            <div className="col-lg-12 mt-3">
                                <button type="submit" className="btn btn-danger" disabled={disable}>Delete</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />
                <Profile />
                {/* PageTitle */}

                {/*blog-grid-area start*/}
                <section
                    className="gradient-bg our-services service-bg-three pt-95 pb-55 pt-lg-55 pb-lg-15">
                    <img
                        className="service-shape-3c"
                        src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-6b.svg"}
                        alt="shape" />
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8">
                                <div className="section-title text-center mb-55">
                                    <h3 className="sect-title mb-25" data-aos="fade-up">Deactivating or deleting your account</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-4 gx-xxl-5">
                            <div className="col-lg-12 col-md-12" data-aos="fade-up" data-aos-delay="100">
                                <div className="feature-style-five mb-45">
                                    <div className="row">
                                        <div className="container">
                                            <p className="text-danger mb-20">Disclaimer for User Account Deletion Requests:</p>
                                            <p className="text-white">At our website, we take the privacy and security of our users very seriously. We understand that you may want to delete your account with us for various reasons. If you wish to do so, please be aware of the following:</p>
                                            <p className="text-white">1. Once you delete your account, all of your personal data and information will be permanently removed from our system, and we will not be able to recover it.</p>
                                            <p className="text-white">2. Deleting your account will also result in the removal of any content or data associated with it, such as posts, comments, or messages.</p>
                                            <p className="text-white">3. Some information may still be retained in our backup systems for a limited period of time, as part of our routine data backup procedures. However, this data will not be accessible or used for any other purpose.</p>
                                            <p className="text-white">4. We may also be required by law to retain certain data or information for a specific period of time, even after your account has been deleted.</p>
                                            <p className="text-white">Please note that if you have published any blog posts on our website, those posts will not be deleted, even if you delete your account. Your username will be removed from the post, and the post will be associated with an anonymous user.</p>
                                            <p className="text-white">By requesting the deletion of your account, you acknowledge and agree to the above terms and conditions. To proceed with your account deletion request, please contact our support team through the designated channels, and we will assist you in the process.</p>
                                            <p className="text-white">Please note that this disclaimer may be updated or revised from time to time, and we encourage you to check it periodically for any changes. If you have any questions or concerns regarding our account deletion policy, please do not hesitate to contact us.</p>
                                            <button type="button" className="btn btn-warning mt-20" onClick={delayModal}>
                                                Delete Account
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*blog-grid-area end*/}

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Feedback