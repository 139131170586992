import React, { Fragment, useState, useEffect } from 'react'
import Slider from 'react-slick'
import axios from 'axios'
import AWN from 'awesome-notifications'

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    loop: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    padding: 15,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};

const Feedback = () => {

    const [feedbackContent, setUser] = useState([]);

    useEffect(() => {
        axios.post(`https://api.pentotest.com/fetchfeedback`)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setUser(res.data.data);
                }
            }).catch(error => {
                console.log(error);
            });

    }, []);

    return (
        <Fragment>
            <Slider {...settings} className="row gx-0 feedback-slider-two">
                {feedbackContent.map((data, i) => (
                    <div key={i} className="col-lg-6">
                        <div className="feedback-style-two">
                            <h4 className="fw-medium mb-25">“ {data.feedback} ”</h4>
                            <div className="d-sm-flex align-items-center justify-content-between">
                                <div className="author d-flex align-items-center justify-content-center mt-35">
                                    <img className="avatar" src={data.image === 'default_image.png' ? process.env.PUBLIC_URL + '/assets/img/default_image.png' : data.image} alt={data.name} />
                                    <div className="author-info">
                                        <h4 className="text-capitalize">{data.name}</h4>
                                        {/* <p className="text-white">{val.desig}</p> */}
                                    </div>
                                </div>
                                <div className="rating mt-35">
                                    {data.rating === 1 ? <>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                    </> : data.rating === 2 ? <>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                    </> : data.rating === 3 ? <>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                    </> : data.rating === 4 ? <>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                    </> : <>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                        <a href="javascript:void(0)"><i className="bi bi-star-fill"></i></a>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </Fragment>
    )
}

export default Feedback