import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

//componets
import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import NewsletterTwo from '../../../components/call-to-action/NewsletterTwo'
import Footer from '../../../components/footer/Footer'

const Termsandconditions = () => {

    return (
        <Fragment>
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>Refund and Cancellation Policy | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}

                {/* ThemeMenuThree */}
                <Header />
                {/* ThemeMenuThree */}

                {/* PageTitle */}
                <PageTitle title="Refund and Cancellation Policy" />
                {/* PageTitle */}

                {/*blog-classic-area start*/}
                <section className="blog-classic-area pt-50 pb-50 pt-lg-0 pb-lg-15">
                    <div className="container">
                        <br />
                        <h2 className="text-white"><strong>Refund and Cancellation Policy</strong></h2>
                        <br />
                        <p className="text-white">Thank you for purchasing from pentotest.com. We strive to deliver high-quality educational content and a smooth purchasing experience. Currently, we do not offer a general money-back guarantee on course purchases, so we encourage you to review all course details, past customer feedback, and requirements before making a purchase.</p>
                        <p className="text-white">However, if you face any issues such as payment errors, or if your payment was successful but the course access was not provided, please contact us immediately. In such cases, where there is a confirmed payment issue or non-delivery of purchased content, we assure you a 100% refund. Our team is committed to ensuring your satisfaction and resolving any transactional issues promptly.</p>
                    </div>
                </section>
                {/*blog-classic-area end*/}

                {/*techy-newsletter start*/}
                <NewsletterTwo />
                {/*techy-newsletter end*/}

                {/* FooterThree start */}
                <Footer />
                {/* FooterThree end */}

            </div>

        </Fragment>
    )
}

export default Termsandconditions