import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import AWN from 'awesome-notifications'

const WidgetFour = () => {

    const [topdata, settopblogs] = useState([]);

    useEffect(() => {
        axios.post(`https://api.pentotest.com/fetchblogsRanFour`)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    settopblogs(res.data.data)
                }
            }).catch(error => {
                console.log(error);
            });
    }, []);


    return (
        <Fragment>
            <ul className="post-list">
                {topdata.map((val, i) => (
                    <li key={i}>
                        <div className="blog-post mb-20">
                            <Link to={`/blogs/${val.slug}`}><img className="avatar" src={val.file} alt="Post Img" /></Link>
                            <div className="post-content">
                                <h6 className="mb-10">
                                    <Link to={`/blogs/${val.slug}`}>{val.title}</Link>
                                </h6>
                                <span className="text-capitalize">{val.author}</span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </Fragment >
    )
}

export default WidgetFour