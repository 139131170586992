import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'

const teamContent = [
    {
        img: 'me.png',
        desig:'FOUNDER/CEO',
        name:'Shahrukh Khan',
        dataAos:'fade-up',
        dataDelay: '100',
        fb: 'https://www.facebook.com/shah96rukh/',
        tw: 'https://twitter.com/shah96rukh/',
        lin: 'https://www.linkedin.com/in/shah96rukh/',
        ins: 'https://www.instagram.com/shah96rukh/'
    },
    {
        img: 'noor.jpg',
        desig:'MANAGER',
        name:'Noor Hamza',
        dataAos:'fade-up',
        dataDelay: '100',
        fb: 'https://www.facebook.com/noormohdhamzazia?mibextid=kFxxJD',
        tw: 'https://twitter.com/shah96rukh/',
        lin: 'https://www.linkedin.com/in/noor-mohammed-hamza-100a2513b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
        ins: 'https://www.instagram.com/hamzazia07?igsh=MWFnZDRvY3Y3ejRrcw=='
    },
    {
        img: 'livek_verma.jpg',
        desig:'SOFTWARE DEVELOPER',
        name:'Vivek Verma',
        dataAos:'fade-up',
        dataDelay: '100',
        fb: 'https://www.facebook.com/shah96rukh/',
        tw: 'https://twitter.com/shah96rukh/',
        lin: 'https://www.linkedin.com/in/vivek-verma2',
        ins: 'https://instagram.com/lj_0.o'
    },
]

const Team = () => {
    return (
        <Fragment>
            <div className="row gx-4 gx-xxl-5 justify-content-center">
                {teamContent.map((val,i) => (
                    <div key={i} className="col-lg-4 col-md-6" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                        <div className="team-style-one text-center mb-45">
                            <Link to="/team-details"><img src={process.env.PUBLIC_URL + `/assets/img/about/${val.img}`} alt="img"/></Link>
                            <div className="team-content">
                                <p>{val.desig}</p>
                                <h3 className="sect-title-two">
                                    <Link to="/team-details">{val.name}</Link>
                                </h3>
                                <div className="team-social">
                                    <a href={val.fb} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-facebook-f text-dark"/>
                                    </a>
                                    <a href={val.tw} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-twitter text-dark"/>
                                    </a>
                                    <a href={val.lin} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-linkedin-in text-dark"/>
                                    </a>
                                    <a href={val.ins} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-instagram text-dark"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default Team