import React,{Fragment} from 'react'

const socialContent = [
    {
        icon: 'fab fa-facebook-f',
        routerPath: 'https://www.facebook.com/shah96rukh/'
    },
    {
        icon: 'fab fa-twitter',
        routerPath: 'https://twitter.com/shah96rukh/'
    },
    {
        icon: 'fab fa-linkedin-in',
        routerPath: 'https://www.linkedin.com/in/shah96rukh/'
    },
    {
        icon: 'fab fa-instagram',
        routerPath: 'https://www.instagram.com/shah96rukh/'
    },
    {
        icon: 'fab fa-youtube',
        routerPath: 'https://www.youtube.com/channel/UCvZqkoAyxS00dKPRhfAqPlA'
    },
]

const SocialFive = () => {
    return (
        <Fragment>
            <div className="text-center text-md-end team-social-three social-style-five d-block mb-40">
               {socialContent.map((val, i)=>(
                 <a key={i} href={val.routerPath} target="_blank" rel="noopener noreferrer">
                    <i className={val.icon}/>
                </a>
               ))}
            </div>
        </Fragment>
    )
}

export default SocialFive
