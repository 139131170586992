import React, {Fragment} from 'react'
import { Link } from 'react-router-dom'


const CategoryWidget = [
    {
        category: 'IMSI Catcher',
        number:'4',
        routerPath: 'blog-details',
    },
    {
        category: 'Networking',
        number:'3',
        routerPath: 'blog-details',
        
    },
    {
        category: 'SCADA',
        number:'2',
        routerPath: 'blog-details',
    },
    {
        category: 'SS7',
        number:'2',
        routerPath: 'blog-details',
    },
    {
        category: 'MOBILE',
        number:'3',
        routerPath: 'blog-details',
    },
]

const CategoryWidgetTwo = () => {
    return (
        <Fragment>
            <ul className="list-none">
                {CategoryWidget.map((val,i)=>(
                    <li key={i}>
                        <Link href={() => false}>{val.category} <span className="f-right">{val.number}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </Fragment>
    )
}

export default CategoryWidgetTwo