import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import AWN from "awesome-notifications";
import { useForm } from 'react-hook-form';

import FeebackFormEmp from '../../components/form/FeebackFormEmp'

const FeedbackForm = () => {

    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [datas, setfeed] = useState([]);

    const onSubmit = dat => {

        const data = { rating: dat.rating, feedback: dat.feedback, name: localStorage.getItem('name'), image: localStorage.getItem("user_image"), user_id: localStorage.getItem("user_id") }

        axios.post(
            `https://api.pentotest.com/feedback`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    new AWN().success(`${res.data.message}`);
                    fetchData()
                }
            }).catch(error => {
                console.log(error);
            });
    }

    function fetchData() {

        const data = { user_id: localStorage.getItem("user_id") }

        axios.post(`https://api.pentotest.com/fetchfeedbackbyuser`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setfeed(res.data.data)
                }
            }).catch(error => {
                console.log(error);
            });

    }

    useEffect(() => {

        const data = { user_id: localStorage.getItem("user_id") }

        axios.post(`https://api.pentotest.com/fetchfeedbackbyuser`, data)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                } else {
                    setfeed(res.data.data)
                }
            }).catch(error => {
                console.log(error);
            });

    }, []);

    return (

        <Fragment>
            <div className="row gx-4 gx-xxl-5">
                <div className="col-lg-12 col-md-12" data-aos="fade-up" data-aos-delay="100">
                    <div className="feature-style-five mb-45">
                        {datas.length > 0 ?
                            datas.map(data => (
                                <div key={data.index}>
                                    <form className="widget-form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-md-12 mb-20">
                                            <div className="text-center mb-5">
                                                <div className="rating">
                                                    {data.rating === 5 ? <><input type="radio" name="rating" value="5" id="5" {...register('rating')} checked="checked" /><label htmlFor="5">☆</label></> : <> <input type="radio" name="rating" value="5" id="5" {...register('rating')} /><label htmlFor="5">☆</label></>}
                                                    {data.rating === 4 ? <> <input type="radio" name="rating" value="4" id="4" {...register('rating')} checked="checked" /><label htmlFor="4">☆</label></> : <> <input type="radio" name="rating" value="4" id="4" {...register('rating')} /><label htmlFor="4">☆</label></>}
                                                    {data.rating === 3 ? <> <input type="radio" name="rating" value="3" id="3" {...register('rating')} checked="checked" /><label htmlFor="3">☆</label></> : <> <input type="radio" name="rating" value="3" id="3" {...register('rating')} /><label htmlFor="3">☆</label></>}
                                                    {data.rating === 2 ? <><input type="radio" name="rating" value="2" id="2" {...register('rating')} checked="checked" /><label htmlFor="2">☆</label></> : <> <input type="radio" name="rating" value="2" id="2" {...register('rating')} /><label htmlFor="2">☆</label></>}
                                                    {data.rating === 1 ? <><input type="radio" name="rating" value="1" id="1" {...register('rating')} checked="checked" /><label htmlFor="1">☆</label></> : <> <input type="radio" name="rating" value="1" id="1" {...register('rating')} /><label htmlFor="1">☆</label></>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-20">
                                            <div className="form-input-box">
                                                <label className="fs-16 text-white">Comments: </label>
                                                <textarea placeholder="Write Message*" defaultValue={data.feedback} {...register('feedback', { required: true, maxLength: 250 })} />
                                                {errors.feedback &&
                                                    errors.feedback.type === "required" &&
                                                    <div className="text-danger">Give me feedback.</div>}
                                                {errors.feedback &&
                                                    errors.feedback.type === "maxLength" &&
                                                    <div className="text-danger">Please enter valid message.</div>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="theme_btn">Submit Now</button>
                                        </div>
                                    </form>
                                </div>
                            ))
                            :
                            <>
                                <FeebackFormEmp />
                            </>
                        }
                    </div>
                </div>

            </div>
        </Fragment>

    )
}

export default FeedbackForm