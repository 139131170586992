import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import AWN from "awesome-notifications"
import ReCaptcha from "@matt-block/react-recaptcha-v2"

import Spinner from '../Spinner'

const SignUp = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [disable, setDisable] = useState(true);
    const [gatCaptcha, setCaptcha] = useState();

    const [Loginshow, setLoginShow] = useState(false);
    const handleLoginClose = () => setLoginShow(false);
    const handleLoginShow = () => setLoginShow(true);

    const onSubmit = (data, e) => {
        handleLoginShow();
        const payload = { name: data.name, email: data.email, password: data.password, checkbox: data.checkbox, captcha: gatCaptcha };
        axios.post(
            `https://api.pentotest.com/signup`, payload)
            .then(res => {
                if (res.data.status === 0) {
                    new AWN().alert(`${res.data.message}`);
                    handleLoginClose();
                } else if (res.data.status === 3) {
                    new AWN().alert(`${res.data.message}`);
                    handleLoginClose();
                    window.location.reload();
                } else if (res.data.status === 400) {
                    new AWN().alert(`${res.data.message}`);
                    handleLoginClose();
                } else {
                    handleLoginClose();
                    e.target.reset();
                    localStorage.setItem("user_id", res.data.data[0].user_id);
                    localStorage.setItem("name", res.data.data[0].name);
                    localStorage.setItem("description", res.data.data[0].description);
                    localStorage.setItem("user_image", res.data.data[0].user_image);
                    localStorage.setItem("token", res.data.data[0].token);
                    localStorage.setItem("role", res.data.data[0].role);
                    window.location.href = '/account';
                }
            }).catch(error => {
                console.log(error);
            });

    }

    function onChange(value) {
        if (value !== null) {
            setCaptcha(value);
            setDisable(false);
        } else {
            setDisable(true);
        }
    }

    return (
        <Fragment>
            <form className="widget-form p-0" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-box mb-30">
                    <input
                        className="text-capitalize"
                        type="text"
                        placeholder="Full Name*"
                        {...register('name', { required: true, maxLength: 20 })}
                    />
                    {errors.name &&
                        errors.name.type === "required" &&
                        <div className="text-danger">Please enter full name.</div>}
                    {errors.name &&
                        errors.name.type === "maxLength" &&
                        <div className="text-danger">Please enter valid full name.</div>}
                </div>
                <div className="input-box mail mb-30">
                    <input
                        className="form-control"
                        type="email"
                        placeholder="Email Address*"
                        {...register('email', { required: true, maxLength: 30, pattern: /^\S+@\S+$/i })}
                    />
                    {errors.email &&
                        errors.email.type === "required" &&
                        <div className="text-danger">Please enter email address.</div>}
                    {errors.email &&
                        errors.email.type === "maxLength" &&
                        <div className="text-danger">Please enter valid email address.</div>}
                    {errors.email &&
                        errors.email.type === "pattern" &&
                        <div className="text-danger">Please enter valid email address.</div>}
                </div>
                <div className="input-box pass mb-30">
                    <input
                        className="form-control"
                        type="password"
                        placeholder="Password*"
                        {...register('password', { required: true, maxLength: 20 })}
                    />
                    {errors.password &&
                        errors.password.type === "required" &&
                        <div className="text-danger">Please enter password.</div>}
                    {errors.password &&
                        errors.password.type === "maxLength" &&
                        <div className="text-danger">Please enter valid password.</div>}
                </div>
                <div className="input-check mb-30">
                    <input type="checkbox" 
                        {...register('checkbox', { required: true })}
                    />
                    <label className="form-check-label">I agree to the <Link className="text-primary"
                        to="/terms-and-conditions">terms &amp; conditions</Link></label>
                    {errors.checkbox &&
                        errors.checkbox.type === "required" &&
                        <div className="text-danger">You must agree before submitting.</div>}
                </div>
                <div className="col-lg-12 mb-20">
                    <ReCaptcha
                        siteKey="6Lcd74MkAAAAAFzbtAGPRmgoP-T9cH6YaXAsaP_q"
                        theme="light"
                        size="normal"
                        onSuccess={(captcha) => onChange(captcha)}
                        onExpire={() => onChange(null)}
                        onError={() => onChange(null)}
                    />
                </div>
                <div className="col-12">
                    <button className="theme_btn" type="submit" disabled={disable}>Signup Now</button>
                    <div className={Loginshow ? `divOne` : 'divOne d-none'}>
                        <div className="text-center">
                            <Spinner />
                        </div>
                    </div>
                    <span className="divider">OR</span>
                    <p className="text-center mt-4">Already you have an account ? <Link to="/login">Login</Link></p>
                </div>
            </form>
        </Fragment>
    )
}

export default SignUp