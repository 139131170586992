import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'

import Header from '../../../components/header/Header'
import PageTitle from '../../../components/page-title/PageTitle'
import FeatureThree from '../../../components/feature/FeatureThree'
import BlockStyleAbout from '../../../components/block/BlockStyleAbout'
import Newsletter from '../../../components/call-to-action/Newsletter'
import Footer from '../../../components/footer/Footer'
import ModalVideos from '../../../components/modal-video/ModalVideos'


const About = () => {
    const [open, setOpen] = useState(false);
    const OpenModal = () => {
        setOpen(!open)
    }

    return (
        <Fragment>
            <ModalVideos isOpen={open} onClick={OpenModal} />
            <div className="main-page-wrapper">
                {/* Helmet start */}
                <Helmet>
                    <title>About | Pentotest - Cyber Security &amp; Technology Blogs and Website Builder</title>
                </Helmet>
                {/* Helmet end */}
                <Header />

                {/* PageTitle */}
                <PageTitle title="About Us" />
                {/* PageTitle */}

                {/*techy-feature start*/}
                <section className="techy-feature pt-150 pt-lg-100 pb-30 pb-lg-15">
                    <div className="container">
                        <FeatureThree />
                    </div>
                </section>
                {/*techy-feature end*/}

                {/*about-techy start*/}
                <section className="about-techy about-techy-4d pt-140 pb-75 pt-lg-60 pb-lg-15">
                    <img className="about-shape-4d shape-1b d-none d-xl-inline-block" src={process.env.PUBLIC_URL + "/assets/img/shape/star-3b.svg"} alt="shape" />
                    <img className="about-shape-4d shape-2b d-none d-lg-inline-block" src={process.env.PUBLIC_URL + "/assets/img/shape/star-4b.svg"} alt="shape" />
                    <img className="about-shape-4d shape-3b d-none d-md-inline-block" src={process.env.PUBLIC_URL + "/assets/img/shape/star-4b.svg"} alt="shape" />
                    <img className="about-shape-4d shape-4b d-none d-md-inline-block" src={process.env.PUBLIC_URL + "/assets/img/shape/star-7c.svg"} alt="shape" />
                    <img className="about-shape-4d shape-5b d-none d-xl-inline-block" src={process.env.PUBLIC_URL + "/assets/img/shape/star-4b.svg"} alt="shape" />
                    <div className="container">
                        <div className="row gx-4 gx-xxl-5 align-items-center">
                            <div className="col-lg-7" data-aos="fade-right" data-aos-delay={100}>
                                <div className="about-img-wrapper-four position-relative mb-45">
                                    <img className="main-img" src={process.env.PUBLIC_URL + "/assets/img/about/about-4d.jpg"} alt="img" />
                                    <img className="shape-one" src={process.env.PUBLIC_URL + "/assets/img/shape/line-round-2a.svg"} alt="shape" />
                                </div>
                            </div>
                            <div className="col-lg-5" data-aos="fade-left" data-aos-delay={100}>
                                <BlockStyleAbout />
                            </div>
                        </div>
                    </div>
                </section>
                {/*about-techy end*/}

                {/*techy-newsletter start*/}
                <Newsletter />
                {/*techy-newsletter end*/}

                {/*FooterThree start*/}
                <Footer />
                {/*FooterThree end*/}

            </div>

        </Fragment>
    )
}

export default About